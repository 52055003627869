<template>
  <div>
    <main class="section" v-if="isLoading">
      <p>{{ $t("forms.contract.sign") }}</p>
      <p>
        {{ $t("forms.contract.complete") }}
      </p>
      <p>{{ $t("forms.contract.privacy") }}</p>

      <p v-if="false">
        {{ $t("forms.contract.revied_email", candidateProfile.email) }}
      </p>

      <b-button
        v-if="!candidateProfile.preferences.contract_signed"
        inverted
        type="is-blue"
        icon-pack="fas"
        icon-left="arrow-right"
        class="button is-large has-border-blue has-hover-bottom ml-2"
        @click="onClick"
      >
        {{ $t("users.candidate.form.fields.contract.actions.sign") }}
      </b-button>
      <CardValidationField
        v-if="hasError && hasErrorMessage"
        :is-next-step="isNextStep"
        :message="hasErrorMessage"
      />

      <div v-if="candidateProfile.preferences.contract_signed">
        <h3>{{ $t("forms.contract.contract_signed") }}</h3>
      </div>
    </main>
  </div>
</template>

<script>
import CardValidationField from "@/components/UI/card/CardValidationField";
export default {
  name: "ContractFormItem",
  props: ["field", "indexStep", "indexField"],
  components: { CardValidationField },
  data() {
    return {
      isLoading: true,
      isNextStep: false,
      componentReady: true,
      hasError: false,
      hasErrorMessage: this.$t("error.validation.sign_the_contract_valid"),
    };
  },

  computed: {
    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },
    fieldsUserProfile() {
      return [
        {
          value_name: "contract",
          required: true,
        },
      ];
    },
  },
  methods: {
    onClick() {
      window.open(this.candidateProfile.embedded_signing_url, "_self");
    },
    validStep(steps) {
      this.isNextStep = true;
      steps.forEach((element) => {
        if (element.required === true) {
          if (this.candidateProfile.preferences.contract_signed) {
            element.isValid = true;
          } else {
            this.hasError = true;
            element.isValid = false;
          }
        }
      });

      if (steps.filter((element) => element.isValid === false).length === 0) {
        steps.isStepValid = true;
      } else {
        steps.isStepValid = false;
      }
    },
  },
};
</script>
<style scoped>
.section {
  padding: 0;
}
p {
  margin-bottom: 40px;
}
</style>
