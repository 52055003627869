<template>
  <b-button
    inverted
    type="is-blue"
    :size="size"
    class="button has-border-blue has-hover-bottom"
    :disabled="processing || disabled"
    @click.prevent="resumeOrderSubscription"
  >
    {{ $t("order.actions.subscription.resume") }}
  </b-button>
</template>
<script>
import { mapActions } from "vuex";

export default {
  name: "BtnResumeOrderSubscription",
  props: {
    order: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "is-large",
    },
  },
  data() {
    return {
      processing: false,
    };
  },
  methods: {
    ...mapActions("order-subscription", ["resumeSubscription"]),

    resumeOrderSubscription() {
      this.$buefy.dialog.confirm({
        title: this.$t("order.subscription.reactivate_subscription"),
        message: this.$t("order.subscription.confirm_reactivate_subscription", {
          reference: this.order.reference,
        }),
        confirmText: this.$t("order.subscription.reactivate"),
        cancelText: this.$t("common.actions.cancel"),
        type: "is-warning",
        onConfirm: async () => {
          this.processing = true;

          try {
            await this.resumeSubscription(this.order.reference);

            this.$store.dispatch("notify", {
              type: "is-info",
              message: this.$t("order.subscription.subscription_reactivated", {
                reference: this.order.reference,
              }),
            });
          } catch (error) {
            this.processing = false;
            const message =
              error.response.data.message == "subscription_ended"
                ? this.$t("order.subscriptions.messages.subscription_ended")
                : this.$t("error.validation.reactivate_subscription_error") +
                  ` ${this.order.reference}.`;

            this.$store.dispatch("notify", {
              type: "is-danger",
              message: message,
            });
          }

          this.$emit("get-data");
        },
      });
    },
  },
};
</script>
