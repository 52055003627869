<template>
  <b-button
    outlined
    type="is-blue-grey"
    class="button is-large has-text-blue has-border-none"
    :disabled="disabled"
    @click.prevent="saveDraft"
  >
    <span class="is-flex is-align-items-center">
      <IconSave class="mr-1" />
      <span>{{ $t("text.actions.save_draft") }}</span>
    </span>
  </b-button>
</template>
<script>
export default {
  name: "button-save-and-continue-later",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    saveDraft() {
      this.$emit("save-draft");
    },
  },
};
</script>
