<template>
  <b-table
    hoverable
    backend-filtering
    backend-pagination
    backend-sorting
    paginated
    class="textsTab radius mt-4 table-striped"
    :data="users.data"
    :default-sort="[config.sort, config.sortDirection]"
    :loading="loading"
    :mobile-cards="true"
    :per-page="config.per_page"
    :show-detail-icon="true"
    :striped="true"
    :total="users.meta.total"
    :debounce-search="300"
    @sort="onSort"
    @page-change="onPageChange"
    @filters-change="onFilterChange"
  >
    <b-table-column
      :searchable="true"
      field="reference"
      label="#"
      sortable
      width="0"
    >
      <div slot="default" slot-scope="props">
        <div class="is-visible-medium-small-none">
          <div
            class="tag is-info clickable"
            @click="copyText(props.row.reference)"
          >
            <b-tooltip v-if="isClicked" :label="$t('text.copied')">
              {{ truncateReference(props.row.reference) }}
            </b-tooltip>

            <b-tooltip v-else :label="`#${props.row.reference}`">
              {{ truncateReference(props.row.reference) }}
            </b-tooltip>
          </div>
        </div>
        <div class="is-visible-desktop-only">
          <div class="tag is-info">#{{ props.row.reference }}</div>
        </div>
      </div>
    </b-table-column>
    <b-table-column
      :label="$t('forms.personal.fields.profile_photo.label')"
      field="avatar"
      width="0"
    >
      <div slot="default" slot-scope="props">
        <div v-if="props.row.avatar">
          <UserAvatar :user="props.row" />
        </div>
      </div>
    </b-table-column>
    <b-table-column
      :searchable="true"
      field="firstname"
      :label="$ts('forms.personal.fields.first_name')"
      sortable
      width="0"
    >
      <div slot="default" slot-scope="props">
        {{ props.row.firstname }}
      </div>
      <div slot="searchable" slot-scope="props">
        <b-input
          expanded
          v-model="props.filters[props.column.field]"
          :placeholder="
            $ts('forms.personal.fields.first_name').toLowerCase() + '...'
          "
          type="text"
        />
      </div>
    </b-table-column>
    <b-table-column
      :searchable="true"
      field="lastname"
      :label="$ts('forms.personal.fields.last_name')"
      sortable
      width="0"
    >
      <div slot="default" slot-scope="props">
        {{ props.row.lastname }}
      </div>
      <div slot="searchable" slot-scope="props">
        <b-input
          expanded
          v-model="props.filters[props.column.field]"
          :placeholder="
            $ts('forms.personal.fields.last_name').toLowerCase() + '...'
          "
          type="text"
        />
      </div>
    </b-table-column>
    <b-table-column
      :searchable="true"
      field="email"
      :label="$ts('forms.personal.fields.email')"
      sortable
      width="0"
    >
      <div slot="default" slot-scope="props">
        {{ props.row.email }}
      </div>
      <div slot="searchable" slot-scope="props">
        <b-input
          expanded
          v-model="props.filters[props.column.field]"
          :placeholder="
            $ts('forms.personal.fields.email').toLowerCase() + '...'
          "
          type="text"
        />
      </div>
    </b-table-column>
    <b-table-column
      :searchable="true"
      field="company.name"
      :label="$ts('company.titles.company')"
      sortable
      width="0"
    >
      <div slot="default" slot-scope="props">
        <div v-if="props.row.company">
          <b-tooltip
            :label="`${$t('forms.company.siren_only')} : ${
              props.row.company.siren
            }`"
          >
            {{ props.row.company?.name }}
          </b-tooltip>
        </div>
      </div>
    </b-table-column>
    <b-table-column
      v-if="users.data.some((user) => user.orders_count)"
      field="orders_count"
      :label="$tp('order.titles.order')"
      width="0"
    >
      <div slot="default" slot-scope="props">
        {{ props.row.orders_count || "" }}
      </div>
    </b-table-column>
    <b-table-column
      field="company.pennylane_id"
      label="Pennylane"
      centered
      width="0"
    >
      <div slot="default" slot-scope="props">
        <template v-if="props.row.company">
          <div
            v-if="
              props.row.company.pennylane_id ||
              props.row.company.pennylane_supplier_id
            "
            class="company-pennylane-status-box has-background-success"
          >
            ✓
          </div>
          <div
            v-else
            class="company-pennylane-status-box has-background-danger"
          >
            x
          </div>
        </template>
      </div>
    </b-table-column>
    <b-table-column
      :searchable="true"
      field="roles"
      :label="$tp('role.role')"
      width="0"
    >
      <template #searchable="props">
        <b-dropdown :triggers="['hover']" aria-role="list">
          <template #trigger>
            <b-button class="is-white has-text-blue is-height-auto" outlined>
              <span
                v-if="
                  !props.filters['roles']?.length ||
                  !Array.isArray(props.filters['roles'])
                "
              >
                {{ $t("common.words.all") }}
              </span>
              <span
                v-else
                class="is-text-wrap-wrap is-flex is-align-items-center"
              >
                <IconFilter class="mt-1 mr-1" style="min-width: 15px" />
                <span>{{ props.filters["roles"].join(", ") }}</span>
              </span>
            </b-button>
          </template>

          <b-dropdown-item
            v-for="(option, index) in roleOptions"
            :key="index"
            custom
            aria-role="listitem"
          >
            <b-checkbox
              v-model="props.filters['roles']"
              type="is-primary ml-1"
              class="is-flex is-flex-direction-row-reverse is-justify-content-space-between"
              :true-value="option"
              :native-value="option"
            >
              <span>{{ option }}</span>
            </b-checkbox>
          </b-dropdown-item>
          <b-dropdown-item
            v-if="props.filters['roles']?.length"
            custom
            aria-role="listitem"
            class="is-flex is-justify-content-center"
          >
            <b-button
              type="is-white"
              class="is-size-7"
              @click="() => (props.filters['roles'] = [])"
            >
              {{ $t("dashboard.table.remove_filter") }}
            </b-button>
          </b-dropdown-item>
        </b-dropdown>
      </template>
      <div slot="default" slot-scope="props">
        <div v-if="props.row.roles?.length">
          <b-tag
            v-for="(role, index) in props.row.roles"
            type="is-info"
            class="mx-1 mb-1"
            :key="index"
          >
            <span v-if="role?.name">{{ role.name }}</span>
            <span v-else-if="role">{{ role }}</span>
          </b-tag>
        </div>
      </div>
    </b-table-column>
    <b-table-column field="" :label="$tp('admin.actions.action')" width="0">
      <div slot="default" slot-scope="props">
        <b-button
          class="has-text-blue mb-1"
          @click.prevent="editUser(props.row, props.row.company?.name)"
        >
          <IconPen class="mt-1" />
        </b-button>
        <b-button
          href="#"
          class="has-text-blue"
          @click.prevent="changeUser(props.row)"
        >
          <IconUser class="mt-1" />
        </b-button>
      </div>
    </b-table-column>
  </b-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import UserAvatar from "@/components/Model/User/UserAvatar.vue";

export default {
  name: "UsersTable",
  components: { UserAvatar },
  data() {
    return {
      loading: false,
      isClicked: false,
    };
  },
  computed: {
    ...mapState("user", {
      users: (state) => state.users,
      config: (state) => state.usersConfig,
    }),

    ...mapGetters("auth", ["homepage"]),
    companySearch: {
      get() {
        return this.$store.state.company.companySearch;
      },
      set(value) {
        this.$store.commit("company/setCompanySearch", value);
      },
    },

    roleOptions() {
      return Object.keys(this.$t("role"))
        .filter((key) => key !== "role")
        .sort();
    },
  },
  methods: {
    ...mapActions("user", ["getUsers"]),
    ...mapActions("auth", ["connectUser"]),

    loadAsyncData() {
      this.loading = true;
      this.getUsers()
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSort(field, order) {
      this.config.sort = field;
      this.config.sortDirection = order;

      this.loadAsyncData();
    },

    onFilterChange(filter) {
      let filters = {};

      Object.keys(filter).forEach((element) => {
        if (element === "roles" && !Array.isArray(filter[element])) {
          filter[element] = [filter[element]];
        }

        filters[element] = filter[element];
      });

      this.config.filters = filters;

      this.loadAsyncData();
    },

    onPageChange(page) {
      this.config.page = page;

      this.loadAsyncData();
    },

    editUser(user, companyName) {
      this.companySearch = companyName;
      this.$emit("create-user", user);
    },

    changeUser(user) {
      this.$buefy.dialog.confirm({
        title: this.$t("admin.user.actions.account_change.confirm.title"),
        message: this.$t("admin.user.actions.account_change.confirm.message", {
          lastname: user.lastname,
          firstname: user.firstname,
        }),
        confirmText: this.$t("common.words.yes"),
        cancelText: this.$t("common.actions.cancel"),
        type: "is-warning",
        onConfirm: () => {
          this.connectUser(user.reference)
            .finally(() => {
              this.config.filters = {};
              this.config.page = 1;

              this.$router.push({ path: this.homepage });
            })
            .catch((e) => {
              this.$store.dispatch("fireError", e);
            });
        },
      });
    },

    getData() {
      this.$emit("get-data");
    },

    truncateReference(reference) {
      const parts = reference.split("-");
      return `${parts[0]}-${parts[1]}...`;
    },

    copyText(reference) {
      navigator.clipboard
        .writeText(reference)
        .then(() => {
          this.isClicked = true;
          setTimeout(() => {
            this.isClicked = false;
          }, 500);
        })
        .catch((err) => {
          console.error("Error copying text: ", err);
        });
    },
  },
};
</script>

<style scoped>
.dialog .modal-card {
  max-width: 100% !important;
  width: auto;
}

.clickable :hover {
  cursor: pointer;
}
</style>
