<template>
  <b-table
    :data="creditHistories"
    :loading="loading"
    class="textsTab radius mt-4 table-striped"
    :mobile-cards="true"
    hoverable
    :per-page="config.per_page"
    :show-detail-icon="true"
    :striped="true"
    :total="config.total"
    backend-filtering
    backend-pagination
    backend-sorting
    paginated
    @page-change="onPageChange"
    @filters-change="onFilterChange"
  >
    <b-table-column
      field="tva"
      :label="$t('common.words.date')"
      sortable
      width="0"
    >
      <div slot="default" slot-scope="props">
        {{ props.row.created_at | toDate }}
      </div>
    </b-table-column>

    <b-table-column
      field="tva"
      :label="$t('transaction.collection.fields.amount')"
      sortable
      width="0"
    >
      <div slot="default" slot-scope="props">
        {{ props.row.type == "credit" ? "+" : "-" }}
        {{ props.row.amount | toCurrency }}
      </div>
    </b-table-column>
  </b-table>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { prepareRequestFilters } from "@/utils/index";

export default {
  name: "CreditHistoriesTable",

  props: {
    companyId: {
      type: Number,
      required: true,
    },
  },

  mounted() {
    this.loadAsyncData();
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState("credit", {
      creditHistories: (state) => state.creditHistories,
      config: (state) => state.creditHistoriesConfig,
    }),
  },

  methods: {
    ...mapActions("credit", ["getCreditHistories"]),

    loadAsyncData() {
      this.loading = true;

      this.getCreditHistories(this.companyId)
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSort(field, order) {
      this.config.sort = field;
      this.config.sortDirection = order;

      this.loadAsyncData();
    },

    onFilterChange(filter) {
      this.config.filters = prepareRequestFilters(filter);

      this.loadAsyncData();
    },

    onPageChange(page) {
      this.config.page = page;

      this.loadAsyncData();
    },

    getData() {
      this.$emit("get-data");
    },
  },
};
</script>
