<template>
  <div
    class="is-flex is-flex-direction-column is-justify-content-space-between"
  >
    <section
      class="section pt-4 pb-6 is-flex is-flex-wrap-wrap is-justify-content-space-between has-background-white"
    >
      <div v-if="candidateProfile.preferences.status === 9">
        <h2 class="pb-4">{{ $t("forms.candidate.submission_thank_you") }}</h2>
        <p>{{ $t("forms.candidate.what_now") }}</p>
        <p>{{ $t("forms.candidate.onboarding_manager_review") }}</p>
        <p>{{ $t("forms.candidate.email_selection_result") }}</p>
        <p>{{ $t("forms.candidate.test_proposal_if_chosen") }}..</p>
        <p>
          {{ $t("forms.candidate.team_integration_if_success") }}
        </p>
      </div>
      <div v-else>
        <h2 class="pb-4">{{ $t("forms.candidate.application_rejected") }}</h2>
        <p>{{ $t("forms.candidate.what_now") }}</p>
        <p>
          {{ $t("forms.candidate.reapply_in_3_months") }}
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  title: "metadata.title.user.candidatePending",
  name: "CandidatePending",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .hero-head {
    height: 65vh;
  }
}
@media (min-width: 768px) and (max-width: 1210px) {
  .hero-head {
    height: 40vh;
  }
}
</style>
