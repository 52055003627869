<template>
  <div>
    <Modal
      v-if="isModalCreateActive && componentReady"
      ref="baseModal"
      class="is-fullheight left-modal"
    >
      <template v-slot:header>
        <h3 v-if="company?.reference">
          {{ $t("common.actions.modify") }} {{ company.name }}
        </h3>
        <CloseModalButton @click="hide" />
      </template>

      <EditCompanyForm :companyInfos="company" />
    </Modal>
  </div>
</template>

<script>
import Modal from "@/components/UI/Modal";
import CloseModalButton from "@/components/UI/buttons/CloseModalButton";
import EditCompanyForm from "@/components/Model/Company/Form/EditCompanyForm";

export default {
  name: "CreateCompanyModal",
  components: {
    CloseModalButton,
    Modal,
    EditCompanyForm,
  },
  computed: {
    company: {
      get() {
        return this.$store.state.company.company;
      },
      set(value) {
        this.$store.commit("company/setCompany", value);
      },
    },
  },
  data() {
    return {
      isModalCreateActive: true,
      componentReady: true,
    };
  },
  methods: {
    show() {
      this.forceRerender();
      this.$refs.baseModal.show();
    },
    hide() {
      this.$refs.baseModal.hide();
    },
    saved() {
      this.$emit("get-data");
      this.hide();
    },
    async forceRerender() {
      this.componentReady = false;
      await this.$nextTick();
      this.componentReady = true;
    },
  },
};
</script>
