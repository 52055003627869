import i18n from "@/i18n/i18n";

/*
 * Returns the plural form of a given message.
 * Always uses 2 as the second argument to indicate plural form.
 */
export function tp(key) {
  return i18n.tc(key, 2);
}

/*
 * Returns the singular form of a given message.
 * Always uses 1 as the second argument to indicate singular form.
 */
export function ts(key) {
  return i18n.tc(key, 1);
}
