<template>
  <article
    :class="{
      'is-flex-direction-column': !field.isUserView,
      'is-flex': !field.isNoFlex,
    }"
  >
    <div
      class="is-flex is-align-items-center p-0 pt-2"
      :class="{
        'column is-flex-grow-2': field.isUserView,
        'is-justify-content-space-between': !field.isUserView,
      }"
    >
      <p
        class="body2 bold has-text-blue"
        :class="{ 'mb-2': !field.isBriefEditor && !field.isUserView }"
      >
        {{ field.label }}
      </p>
      <b-button
        v-if="field.isEditable && !field.isUserView"
        class="is-small has-text-blue has-border-none"
        :class="{ 'my-1': !field.isBriefEditor }"
        @click.prevent="startEditing"
      >
        <IconPen class="iconPen" />
      </b-button>
    </div>
    <div
      v-if="value && value.length !== 0"
      class="briefAnswers"
      :class="{
        'has-background-white radius py-2 px-3':
          !field.isBriefEditor && !field.isUserView,
        'p-0 pt-2 column is-flex-grow-5 is-justify-content-flex-end is-flex':
          field.isUserView,
      }"
    >
      <div
        v-if="field.isHtml"
        :class="{
          'is-flex is-align-items-center': field.isUserView,
        }"
      >
        <p
          class="body1 has-text-blue"
          :class="{
            right: field.isUserView,
          }"
          v-html="value"
        ></p>

        <b-button
          v-if="field.isEditable && field.isUserView"
          class="is-small has-text-blue has-border-none"
          :class="{ 'my-1': !field.isBriefEditor }"
          @click.prevent="startEditing"
        >
          <IconPen class="iconPen" />
        </b-button>
      </div>
      <div
        :class="{
          'is-flex is-align-items-center': field.isUserView,
        }"
        v-else-if="
          typeof value !== 'string' &&
          typeof value !== 'boolean' &&
          value.length > 0
        "
      >
        <div
          :class="{
            'end mt-2': field.isUserView,
          }"
        >
          <div v-for="option in value" :key="option.id">
            <p class="body1 has-text-blue">
              {{ option.name }}
            </p>
          </div>
        </div>

        <b-button
          v-if="field.isEditable && field.isUserView"
          class="is-small has-text-blue has-border-none"
          :class="{ 'my-1': !field.isBriefEditor }"
          @click.prevent="startEditing"
        >
          <IconPen class="iconPen" />
        </b-button>
      </div>

      <div v-else-if="typeof value === 'boolean'">
        <div
          :class="{
            'is-flex is-align-items-center': field.isUserView,
          }"
        >
          <p
            class="body1 has-text-blue"
            :class="{
              right: field.isUserView,
            }"
          >
            {{ value == true ? $t("common.words.yes") : $t("common.words.no") }}
          </p>

          <b-button
            v-if="field.isEditable && field.isUserView"
            class="is-small has-text-blue has-border-none"
            :class="{ 'my-1': !field.isBriefEditor }"
            @click.prevent="startEditing"
          >
            <IconPen class="iconPen" />
          </b-button>
        </div>
      </div>
      <ul v-else>
        <li
          :class="{
            'is-flex is-align-items-center': field.isUserView,
          }"
        >
          <a
            v-if="isLink"
            :href="field.value"
            target="_blank"
            class="body1 has-text-pink"
            :class="{
              right: field.isUserView,
            }"
          >
            {{ value || null }} {{ valueType || null }}
          </a>

          <p
            v-else
            class="body1 has-text-blue"
            :class="{
              right: field.isUserView,
            }"
          >
            {{ value || null }} {{ valueType || null }}
          </p>
          <b-button
            v-if="field.isEditable && field.isUserView"
            class="is-small has-text-blue has-border-none"
            :class="{ 'my-1': !field.isBriefEditor }"
            @click.prevent="startEditing"
          >
            <IconPen class="iconPen" />
          </b-button>
        </li>
      </ul>
    </div>

    <div
      :class="{
        'p-0 pt-2': field.isUserView,
      }"
      v-else
    >
      <ul>
        <li
          :class="{
            'is-flex is-align-items-center': field.isUserView,
          }"
        >
          <p class="body1 has-text-blue">
            {{
              value === false
                ? $t("common.words.no")
                : $t("common.words.not_specified")
            }}
          </p>
          <b-button
            v-if="field.isEditable && field.isUserView"
            class="is-small has-text-blue has-border-none"
            :class="{ 'my-1': !field.isBriefEditor }"
            @click.prevent="startEditing"
          >
            <IconPen class="iconPen" />
          </b-button>
        </li>
      </ul>
    </div>
  </article>
</template>
<script>
export default {
  name: "OrderBriefArticle",
  props: {
    field: {},
    value: {},
    valueType: {},
    isBriefEditor: {},
    isLink: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    startEditing() {
      this.$emit("start-editing");
    },
  },
};
</script>
<style>
.end {
  text-align: end !important;
}
</style>
