<template>
  <div>
    <b-loading v-if="dataProcessing" active />
    <ButtonIcon
      color="pink"
      icon="IconTimes"
      :size="size"
      :text="$t('user.actions.deactivate.title')"
      :disabled="dataProcessing"
      :loading="dataProcessing"
      @click-button="deactivate"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import ButtonIcon from "@/components/UI/buttons/ButtonIcon";

export default {
  name: "BtnDeactivateUser",
  components: {
    ButtonIcon,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    size: {
      default: "small",
      type: String,
    },
  },
  data() {
    return {
      dataProcessing: false,
    };
  },
  methods: {
    ...mapActions("user", ["deactivateUser"]),

    deactivate() {
      this.$buefy.dialog.confirm({
        title: this.$t("user.actions.deactivate.confirm.title"),
        message: this.$t("user.actions.deactivate.confirm.message"),
        confirmText: this.$t("user.actions.deactivate.title"),
        cancelText: this.$t("common.actions.cancel"),
        type: "is-danger",
        hasIcon: true,
        onConfirm: async () => {
          if (this.dataProcessing) {
            return;
          }

          this.dataProcessing = true;

          try {
            await this.deactivateUser(this.user.reference);
            this.$store.dispatch("notify", {
              type: "is-success",
              message: this.$t("user.actions.deactivate.messages.success"),
            });
            this.$emit("user-satus-changed");
          } catch (error) {
            this.dataProcessing = false;
          }
        },
      });
    },
  },
};
</script>
