<template>
  <b-table
    hoverable
    paginated
    backend-filtering
    backend-pagination
    backend-sorting
    class="textsTab radius mt-4 table-striped"
    :data="companies.data"
    :default-sort="[config.sort, config.sortDirection]"
    :loading="loading"
    :mobile-cards="true"
    :per-page="companies.meta?.per_page"
    :total="companies.meta.total"
    :show-detail-icon="true"
    :striped="true"
    :debounce-search="300"
    @sort="onSort"
    @page-change="onPageChange"
    @filters-change="onFilterChange"
  >
    <b-table-column
      :searchable="true"
      field="name"
      :label="$t('forms.company.company_name')"
      sortable
      width="0"
    >
      <div slot="default" slot-scope="props">
        {{ props.row.name }}
      </div>
      <div slot="searchable" slot-scope="props">
        <b-input
          expanded
          v-model="props.filters[props.column.field]"
          type="text"
        />
      </div>
    </b-table-column>
    <b-table-column
      :searchable="true"
      field="address"
      :label="$ts('forms.personal.fields.address')"
      sortable
      width="0"
    >
      <div slot="default" slot-scope="props">
        {{ props.row.address }}
      </div>
    </b-table-column>
    <b-table-column
      field="zipcode"
      :label="$t('forms.personal.fields.zip_code')"
      sortable
      width="0"
    >
      <div slot="default" slot-scope="props">
        {{ props.row.zipcode }}
      </div>
    </b-table-column>
    <b-table-column
      :searchable="true"
      field="city"
      :label="$t('forms.personal.fields.city')"
      sortable
      width="0"
    >
      <div slot="default" slot-scope="props">
        {{ props.row.city }}
      </div>
    </b-table-column>
    <b-table-column
      :searchable="false"
      field="regNumber"
      :label="$t('company.fields.registration_number')"
      sortable
      width="0"
    >
      <div slot="default" slot-scope="props">
        {{ props.row.regNumber }}
      </div>
    </b-table-column>
    <b-table-column
      :searchable="true"
      field="tva"
      :label="$t('forms.company.tva')"
      sortable
      width="0"
    >
      <div slot="default" slot-scope="props">
        {{ props.row.tva }}
      </div>
    </b-table-column>

    <b-table-column
      :searchable="false"
      field="pennylane_id"
      label="Pennylane"
      centered
      width="0"
    >
      <div slot="default" slot-scope="props">
        <div
          v-if="props.row.pennylane_id || props.row.pennylane_supplier_id"
          class="company-pennylane-status-box has-background-success"
        >
          ✓
        </div>
        <div v-else class="company-pennylane-status-box has-background-danger">
          x
        </div>
      </div>
    </b-table-column>

    <b-table-column field="" :label="$tp('admin.actions.action')" width="0">
      <div slot="default" slot-scope="props">
        <b-button
          @click.prevent="editCompany(props.row, props.row.company?.name)"
          class="has-text-blue"
        >
          <IconPen class="mt-1" />
        </b-button>
      </div>
    </b-table-column>
  </b-table>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "CompaniesTable",
  components: {},
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    ...mapState("user", {
      users: (state) => state.users,
    }),
    ...mapGetters("auth", ["homepage"]),
    ...mapState("company", {
      companies: (state) => state.companies,
      config: (state) => state.companiesConfig,
    }),
    companySearch: {
      get() {
        return this.$store.state.company.companySearch;
      },
      set(value) {
        this.$store.commit("company/setCompanySearch", value);
      },
    },
  },
  methods: {
    ...mapActions("company", ["getCompanies"]),

    loadAsyncData() {
      this.loading = true;
      this.getCompanies()
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onSort(field, order) {
      this.config.sort = field;
      this.config.sortDirection = order;
      this.loadAsyncData();
    },

    onFilterChange(filter) {
      let filters = {};

      Object.keys(filter).forEach((element) => {
        filters[element] = filter[element];
      });

      this.config.filters = filters;

      this.loadAsyncData();
    },

    onPageChange(page) {
      this.config.page = page;

      this.loadAsyncData();
    },

    editCompany(company, companyName) {
      this.companySearch = companyName;
      this.$emit("create-company", company);
    },

    getData() {
      this.$emit("get-data");
    },
  },
};
</script>
