<template>
  <form v-if="thematic">
    <b-field :label="$t('order.actions.search_parent_topic')">
      <b-autocomplete
        v-model="thematic.parent.name"
        :data="parentsThematics"
        open-on-focus
        field="name"
        clearable
        expanded
        icon="fas fa-search"
        :placeholder="$t('thematic.fields.parent_topic')"
        @select="(option) => setSelection(option)"
      >
        <template slot-scope="props">
          <div class="media">
            <div class="media-content">
              #{{ props.option.id }} - {{ props.option.name }}
            </div>
          </div>
        </template>
      </b-autocomplete>
    </b-field>

    <b-field :label="$ts('common.fields.name')" sortable>
      <div class="columns">
        <div class="column is-flex-grow-0">
          <b-select v-model="locale">
            <option
              v-for="localeValue in APP_LOCALES"
              :key="localeValue"
              :value="localeValue"
            >
              {{ $t(`locale.${localeValue}`) }}
            </option>
          </b-select>
        </div>
        <div class="column">
          <b-input v-model="thematic[locale]['name']" />
        </div>
      </div>
    </b-field>

    <div class="columns">
      <div class="column">
        <b-field :label="$t('common.fields.price')">
          <b-numberinput v-model="thematic.price" step="0.01" />
        </b-field>
      </div>
      <div class="column">
        <b-field :label="$t('common.fields.writer_price')">
          <b-numberinput v-model="thematic.writer_price" step="0.01" />
        </b-field>
      </div>
      <div class="column is-flex-grow-0">
        <b-field :label="$t('common.fields.billing_type')">
          <BillingType :billing-type="thematic.billing_type" />
        </b-field>
      </div>
    </div>

    <b-button type="is-primary" :loading="saving" @click.prevent="doSave">
      {{ $t("common.actions.save") }}
    </b-button>
  </form>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import { APP_LOCALES } from "@/utils/constants";

import BillingType from "@/components/Model/Billing/BillingType";

export default {
  name: "CreateThematicForm",
  components: { BillingType },
  data() {
    return {
      locale: this.$i18n.locale,
      saving: false,
      search: "",
      APP_LOCALES,
    };
  },
  computed: {
    ...mapGetters("thematic", ["parentsThematics"]),
    ...mapState("thematic", {
      thematics: (state) => state.thematics,
      thematic: (state) => state.thematic,
    }),
    thematics: {
      get() {
        return this.$store.state.thematic.thematics;
      },
      set(value) {
        this.$store.commit("thematic/setThematics", value);
      },
    },
    thematic: {
      get() {
        return this.$store.state.thematic.thematic;
      },
      set(value) {
        this.$store.commit("thematic/setThematic", value);
      },
    },
    getThematics() {
      if (!this.thematics) {
        return [];
      }

      if (this.thematics.parent_id) {
        return this.thematics.find(
          (option) => option.id === this.thematics.parent_id
        );
      }

      return this.thematics.filter((option) => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(this.search.toLowerCase()) >= 0
        );
      });
    },
  },
  methods: {
    ...mapActions("thematic", {
      saveThematic: "save",
      updateThematic: "update",
      getAll: "getThematics",
    }),
    setSelection(option) {
      if (option) {
        this.thematic.parent = option;
        this.thematic.parent_id = option.id;
      } else {
        this.thematic.parent = { name: "" };
        this.thematic.parent_id = null;
      }
    },
    doSave() {
      this.saving = true;

      let endpoint = this.thematic.id
        ? this.updateThematic()
        : this.saveThematic();

      endpoint
        .then((response) => {
          if (this.thematic.id) {
            const thematics = this.thematics.map((obj) => {
              if (obj.id === this.thematic.id) {
                let array = {};

                response.data.thematic.translations.forEach((translation) => {
                  array[translation.locale] = { name: translation.name };
                });

                return {
                  ...obj,
                  ...array,
                  name: response.data.thematic.name,
                };
              }

              return obj;
            });

            this.thematics = thematics;
            this.thematic = response.data.thematic;
            this.getAll().finally(() => {
              this.loading = false;
            });
          } else {
            this.thematics.push(response.data.thematic);
          }
          this.$store.dispatch("notify", {
            type: "is-success",
            message: this.thematic.id
              ? this.$t("thematic.actions.update.messages.success")
              : this.$t("thematic.actions.create.messages.success"),
          });
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        })
        .finally(() => {
          this.saving = false;
          this.$emit("saved");
        });
    },
  },
};
</script>
