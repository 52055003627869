<template>
  <div>
    <div v-if="field.isEditableFormat && !isEditing">
      <OrderBriefArticle
        :isEditing="isEditing"
        :field="field"
        :value="field.value"
        @start-editing="isEditing = true"
      />
      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>

    <b-field
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
      :label="field.label ? field.label : ''"
      :class="{ 'is-danger': !isUrlValid }"
      :message="{ [$t('error.validation.url_valid')]: !isUrlValid }"
    >
      <b-input
        v-model="briefUrlValue"
        type="url b-input"
        :disabled="disabled"
        :placeholder="field.placeholder ? field.placeholder : ''"
      />
    </b-field>

    <CardValidationField
      v-if="!isUrlValid && briefUrlValue !== '' && briefUrlValue !== ' '"
      :field="isUrlValid"
      :is-next-step="isNextStep"
      :message="$t('error.validation.url_valid')"
    />

    <CardValidationField
      v-if="field.required"
      :field="field.isValid"
      :is-next-step="isNextStep"
      :message="$t('wizard.message.required')"
    />
  </div>
</template>
<script>
import CardValidationField from "@/components/UI/card/CardValidationField";
import OrderBriefArticle from "@/components/Model/Order/OrderBriefArticle";

export default {
  name: "BriefUrl",
  props: ["field", "indexStep", "indexField", "isNextStep", "disabled"],
  components: { CardValidationField, OrderBriefArticle },
  data() {
    return {
      isEditing: false,
      briefUrlValue: "",
      isUrlValid: false,
    };
  },
  watch: {
    briefUrlValue(newValue) {
      this.isUrlValid = this.validateUrl(newValue);
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: newValue,
        hasError: !this.isUrlValid,
      });
    },
  },
  created() {
    this.briefUrlValue = this.field.value ? this.field.value : "";
  },
  methods: {
    validateUrl(url) {
      if (!url || Array.isArray(url)) {
        return false;
      }

      return url.match(
        new RegExp(
          /^(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._~#=+]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_.~#?&//=+]*)?(\?[a-zA-Z0-9_]+=[a-zA-Z0-9_]+(&[a-zA-Z0-9_]+=[a-zA-Z0-9_]+)*)?(#[a-zA-Z0-9_-]+)?$/gi
        )
      );
    },
  },
};
</script>
