<template>
  <div>
    <main class="section" v-if="isLoading">
      <form
        class="column is-12-mobile is-12-tablet is-12-desktop is-12-widescreen is-12-fullhd is-flex is-flex-direction-column is-justify-content-space-evenly m-auto"
      >
        <div
          v-for="(element, index) in fieldsUserProfile"
          class="my-1"
          :key="index"
          style="height: 100%"
        >
          <p class="has-text-blue bold" v-if="element.title">
            {{ element.title }}
          </p>

          <component
            v-if="componentReady"
            :key="index"
            :indexStep="index"
            :indexField="index"
            :is="element.name"
            :field="element"
            :isSmallField="element.small"
            :isNextStep="isNextStep"
            @save-result="saveResult"
            :isNotWizard="true"
            class="is-flex is-flex-direction-column is-justify-content-space-evenly mt-1"
          />
        </div>
      </form>
      <CardValidationField
        v-if="hasError"
        :field="false"
        :is-next-step="true"
        :message="hasErrorMessage"
      />
    </main>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import {
  isCountryEuropeanAndNotFrance,
  scrollToValidationError,
} from "@/utils/index";

import BriefText from "@/components/Model/Form/FormItem/BriefText";
import BriefSwitch from "@/components/Model/Form/FormItem/BriefSwitch";
import AddressAutocomplete from "@/components/Model/Form/FormItem/AddressAutocomplete";
import CardValidationField from "@/components/UI/card/CardValidationField";
export default {
  name: "InvoicesInformationFormItem",
  props: ["field", "indexStep", "indexField"],
  components: {
    BriefText,
    AddressAutocomplete,
    BriefSwitch,
    CardValidationField,
  },

  data() {
    return {
      isLoading: true,
      isNextStep: false,
      componentReady: true,
      hasError: false,
      hasErrorMessage: this.$t("error.validation.tva_valid"),
    };
  },

  created() {
    if (
      this.candidateProfile.company &&
      this.candidateProfile.company.country === "France"
    ) {
      this.fieldsUserProfile.push(this.fieldSubjectVat);
    }
  },

  computed: {
    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },

    fieldSubjectVat() {
      return {
        title: this.$t("forms.company.subject_to_tva"),
        name: "BriefSwitch",
        value_name: "subject_vat",
        required: false,
        value: false,
      };
    },

    fieldsUserProfile() {
      return [
        {
          title: this.$ts("forms.company.company"),
          name: "BriefText",
          value_name: "name",
          required: true,
          value: this.candidateProfile.company
            ? this.candidateProfile.company.name
            : " ",
        },
        {
          title: this.$ts("forms.personal.fields.address"),
          name: "AddressAutocomplete",
          value_name: "address",
          required: true,
          value: this.candidateProfile.company
            ? this.candidateProfile.company.address
            : " ",
          changeCityValue: 3,
          changeZipcodeValue: 2,
          changeCountryValue: 10,
        },
        {
          isEditableFormat: false,
          title: this.$t("forms.personal.fields.zip_code"),
          name: "BriefText",
          value_name: "zipcode",
          required: true,
          value: this.candidateProfile.company
            ? this.candidateProfile.company.zipcode
            : " ",
          regex: "\\d{5}$",
          hasErrorMessage: this.$t("error.validation.zip_code_valid"),
        },
        {
          title: this.$t("forms.personal.fields.city"),
          name: "BriefText",
          value_name: "city",
          required: true,
          value: this.candidateProfile.company
            ? this.candidateProfile.company.city
            : " ",
        },
        {
          title: "IBAN",
          name: "BriefText",
          value_name: "iban",
          required: true,
          value: this.candidateProfile.company
            ? this.candidateProfile.company.iban
            : " ",
        },
        {
          title: this.$t("forms.company.siren"),
          name: "BriefText",
          value_name: "siren",
          required: true,
          value: this.candidateProfile.company
            ? this.candidateProfile.company.siren
            : " ",
        },
        {
          title: this.$t("forms.company.tva"),
          name: "BriefText",
          value_name: "tva",
          dynamicRequired: () => {
            const country =
              this.$store.state.user.candidateProfile.company.country;
            return isCountryEuropeanAndNotFrance(country);
          },
          regex:
            "[a-zA-Z]+([+-]?(?=\\.\\d|\\d)(?:\\d+)?(?:\\.?\\d*))(?:[eE]([+-]?\\d+))?",
          hasErrorMessage: this.$t("error.validation.tva_valid"),
          value: this.candidateProfile.company
            ? this.candidateProfile.company.tva
            : " ",
        },
      ];
    },
  },
  methods: {
    ...mapActions("company", ["checkVat"]),

    saveResult(result) {
      if (!this.candidateProfile.company) {
        this.candidateProfile.company = {};
      }

      this.candidateProfile.company[result.value_name] = result.value;

      if (result.index_field <= this.fieldsUserProfile.length - 1) {
        this.fieldsUserProfile[result.index_field].value = result.value;
        this.fieldsUserProfile[result.index_field].value = result.value;
        this.fieldsUserProfile[result.index_field].hasError = result.hasError;
      }

      if (result.value_name === "country") {
        this.handleSubjectVatFieldWhenSaving(result);
      }

      if (result.index_field === 1) {
        this.forceRerender();
      }
    },

    handleSubjectVatFieldWhenSaving(result) {
      const subjectVatField = this.fieldsUserProfile.find(
        (objet) => objet.value_name === "subject_vat"
      );

      if (result.value === "France" && !subjectVatField) {
        this.fieldsUserProfile.push(this.fieldSubjectVat);
      } else if (subjectVatField) {
        this.fieldsUserProfile = this.fieldsUserProfile.pop();
      }

      this.forceRerender();
    },

    async validStep(steps) {
      this.isNextStep = true;
      let vatNumber = "";

      await steps.forEach((step) => {
        const dynamicRequiredIsValid = step.dynamicRequired
          ? step.dynamicRequired()
          : false;

        if (step.required === true || dynamicRequiredIsValid) {
          const companyValue = this.candidateProfile.company[step.value_name];

          if (!companyValue || companyValue === " " || step.hasError) {
            step.isValid = false;
          } else {
            step.isValid = true;

            if (step.value_name === "tva") {
              vatNumber = step.value;
            }
          }
        } else if (step.hasError && step.dynamicRequired === undefined) {
          step.isValid = false;
        } else {
          step.isValid = true;

          if (step.value_name === "tva") {
            vatNumber = step.value;
          }
        }
      });

      if (steps.filter((step) => step.isValid === false).length === 0) {
        if (vatNumber) {
          let response = await this.checkVat(vatNumber).then((response) => {
            steps.isStepValid = response;
            return response;
          });

          if (!response) {
            this.hasError = true;
          } else {
            this.hasError = false;
          }
        } else {
          this.hasError = false;
          steps.isStepValid = true;
        }
      } else {
        steps.isStepValid = false;
      }

      steps.isStepValid ? this.forceRerender() : scrollToValidationError();
    },

    async forceRerender() {
      this.componentReady = false;
      await this.$nextTick();
      this.componentReady = true;
    },
  },
};
</script>
<style scoped>
.section {
  padding: 0;
}
</style>
