<template>
  <section class="section is-fullheight"></section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  title: "metadata.title.auth.actions.login",
  name: "loginView",
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters("auth", ["homepage"]),
  },
  methods: {
    ...mapActions("auth", {
      login: "loginFromFront",
    }),
    connection() {
      this.loading = true;
      this.isSubmit = true;
      this.confirmMail(this.email);
      this.confirmPassword(this.password);
      !this.mailMessage && !this.passwordMessage
        ? this.login({ email: this.email, password: this.password })
            .then(() => {
              this.loading = false;
              this.$router.push(this.homepage);
            })
            .catch((e) => {
              this.$store.dispatch("fireError", e);
              this.loading = false;
            })
        : (this.loading = false);
    },
  },
  async created() {
    let uri = window.location.search.substring(1);
    let params = new URLSearchParams(uri);

    const data = await this.login(atob(params.get("url")));

    if (data.type === "createWriter" || data.type === "createClient") {
      this.$router.push({ path: this.homepage });
    } else {
      this.$router.push({
        path:
          "/customer/new-website/" +
          data.order.website_id +
          "/order/" +
          data.order.reference +
          "?n=1",
      });
    }
  },
};
</script>
