<template>
  <main>
    <router-view></router-view>
  </main>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  title: "metadata.title.auth.dashboard",
  name: "AuthenticatedDashboard",
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
    ...mapGetters("auth", [
      "admin",
      "writer",
      "client",
      "editorManager",
      "corrector",
      "candidate",
    ]),
    filteredDataArray() {
      return (
        this.data?.filter((option) => {
          return (
            option.toString().toLowerCase().indexOf(this.name.toLowerCase()) >=
            0
          );
        }) || []
      );
    },
  },
};
</script>
