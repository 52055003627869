<template>
  <article class="hero box p-4 m-0 is-white my-2">
    <figure>
      <img
        style="height: 7rem; width: 14rem"
        src="/img/box/space.png"
        :title="$t('skribix.boxes.writer_space.img_alt')"
        :alt="$t('skribix.boxes.writer_space.img_alt')"
      />
    </figure>
    <h4>{{ $t("skribix.boxes.writer_space.title") }}</h4>
    <p class="body1">
      {{ $t("skribix.boxes.writer_space.description") }}
    </p>
    <a
      class="mt-4 has-text-blue-light is-flex is-align-items-center"
      href="https://espace-redacteur.skribix.com?msopen=/member/plans/6c7d09781p"
      target="_blank"
    >
      {{ $t("skribix.boxes.common.actions.view_documentation") }}
      <IconArrowRight class="ml-2" />
    </a>
  </article>
</template>
<script>
export default {
  name: "BoxWriterSpace",
};
</script>
