<template>
  <Modal ref="baseModal">
    <template v-slot:header>
      <h3 v-if="thematic?.id">{{ $t("order.actions.edit_topic") }}</h3>
      <h3 v-else>{{ $t("admin.actions.add_thematic") }}</h3>

      <CloseModalButton @click="hide" />
    </template>

    <div v-if="thematic">
      <CreateThematicForm @saved="saved" />
    </div>
  </Modal>
</template>

<script>
import Modal from "@/components/UI/Modal";
import CloseModalButton from "@/components/UI/buttons/CloseModalButton";
import CreateThematicForm from "../Form/CreateThematicForm";
import { mapState } from "vuex";
export default {
  name: "CreateThematicModal",
  components: { CreateThematicForm, CloseModalButton, Modal },
  computed: {
    ...mapState("thematic", {
      thematic: (state) => state.thematic,
      thematics: (state) => state.thematics,
    }),
  },
  methods: {
    show() {
      this.$refs.baseModal.show();
    },
    hide() {
      this.$refs.baseModal.hide();
    },
    saved() {
      this.$emit("saved");
      this.hide();
    },
  },
};
</script>
