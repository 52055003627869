<template>
  <span>
    <span
      v-if="!showLess && editorsList && editorsList.length > 3"
      class="avatar-container is-flex is-align-items-center"
    >
      <span
        v-for="(user, index) in sortedEditorsList.slice(0, 3)"
        :key="index"
        class="avatar"
        :style="{
          right: `${index * 10}px`,
        }"
      >
        <UserAvatar
          :class="`is-border-${borderColor} rounded`"
          :user="user"
          :colorIconWhite="colorIconWhite"
          v-bind="userAvatarProps(user)"
        />
      </span>
      <button
        class="avatar is-rounded has-border-white rounded opacityButton has-background-grey-darker"
        style="right: 3.9em"
        @click.prevent="toggleAvatars()"
      >
        <img
          src="/img/svg/icon_more_horizontal.svg"
          :alt="$t('common.words.icon')"
          :title="$t('common.words.icon')"
        />
      </button>
    </span>
    <span v-else class="avatar-container is-flex is-align-items-center">
      <span
        v-for="(user, index) in sortedEditorsList"
        :key="index"
        class="avatar"
        :style="{
          right: `${index * 10}px`,
        }"
        @click.prevent="toggleAvatars()"
      >
        <UserAvatar
          :class="`is-border-${borderColor} rounded online-animated-5`"
          :colorIconWhite="colorIconWhite"
          :user="user"
          v-bind="userAvatarProps(user)"
        />
      </span>
    </span>
  </span>
</template>
<script>
import { mapGetters } from "vuex";
import UserAvatar from "@/components/Model/User/UserAvatar";

export default {
  name: "users-avatars",
  components: { UserAvatar },
  props: {
    editorsList: {
      type: Array,
      required: true,
    },
    borderColor: {
      type: String,
      default: "white",
    },
    colorIconWhite: {
      type: Boolean,
      default: false,
    },
    onlines: {
      validator: function (value) {
        return Array.isArray(value) || typeof value === "object" || !value;
      },
      default: null,
    },
    hasBlinkAnimation: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { showLess: false };
  },
  methods: {
    toggleAvatars() {
      this.showLess = !this.showLess;
    },
  },
  computed: {
    ...mapGetters("auth", ["clientOrWriterRoleOnly", "userReference"]),

    sortedEditorsList() {
      if (!this.onlines || !Object.keys(this.onlines).length) {
        return this.editorsList;
      }

      return [...this.editorsList].sort((a) => {
        if (a && this.onlines[a.reference]) {
          return -1;
        }

        return 1;
      });
    },

    userAvatarProps() {
      return (user) => {
        if (
          (this.clientOrWriterRoleOnly &&
            user.reference !== this.userReference) ||
          !this.onlines ||
          !this.onlines[user.reference]
        ) {
          return {};
        }

        return {
          isOnline: true,
          hasBlinkAnimation: this.hasBlinkAnimation,
          tooltip:
            user.firstname +
            " - " +
            this.$t("user.tooltip.active_on") +
            this.onlines[user.reference].map((item) => item).join(", "),
        };
      };
    },
  },
};
</script>
<style scoped>
.avatar-container {
  position: relative;
}

.avatar {
  position: relative;
}
.opacityButton {
  opacity: 0.7;
  width: 32px;
  height: 32px;
}
</style>
