<template>
  <article
    class="approveCard card is-flex is-flex-direction-column is-align-items-center is-justify-content-center my-2 has-border-none py-6"
  >
    <div class="card-content has-text-centered pb-4">
      <h4>
        {{
          $t("forms.wizard.template.construct.confirms.create_or_update.title")
        }}
      </h4>
    </div>
    <div class="buttons is-flex is-flex-direction-column">
      <b-button
        type="is-pink"
        inverted
        class="button is-large has-border-pink has-hover-bottom"
        @click.prevent="updateTemplate"
      >
        {{
          $t(
            "forms.wizard.template.construct.confirms.create_or_update.actions.update_existing"
          )
        }}
      </b-button>
      <b-button
        type="is-link"
        inverted
        class="button is-large has-border-blue has-hover-bottom"
        @click.prevent="createTemplate"
      >
        {{
          $t(
            "forms.wizard.template.construct.confirms.create_or_update.actions.save_new"
          )
        }}
      </b-button>
      <b-button
        v-if="isExistingTemplate"
        type="is-pink"
        inverted
        class="button is-large has-border-pink has-hover-bottom"
        @click.prevent="validExistingTemplate"
        >{{
          $t(
            "forms.wizard.template.construct.confirms.create_or_update.actions.validate_without_saving"
          )
        }}
      </b-button>
    </div>
  </article>
</template>
<script>
export default {
  name: "templateCreateOrUpdateModal",
  props: ["isExistingTemplate"],
  methods: {
    updateTemplate() {
      this.$emit("edit-template");
    },
    createTemplate() {
      this.$emit("create-template", name);
    },
    validExistingTemplate() {
      this.$emit("valid-existing-template");
    },
  },
};
</script>
<style scoped>
@media (min-width: 1024px) {
  .approveCard .card-content {
    width: 80%;
  }
}
</style>
