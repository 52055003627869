<template>
  <div></div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "PopupUnpaidMonthlyInvoice",

  mounted() {
    this.getUnpaidMonthlyInvoice().then((response) => {
      const invoiceNumber = response.data?.invoice?.invoice_number;
      const unpaidMonthlyInvoiceNotified =
        localStorage.getItem("unpaidMonthlyInvoiceNotified") || "";

      if (invoiceNumber) {
        const alreadyNotified = unpaidMonthlyInvoiceNotified == invoiceNumber;

        if (!alreadyNotified) {
          this.showUnpaidInvoiceNotification(invoiceNumber);
          localStorage.setItem("unpaidMonthlyInvoiceNotified", invoiceNumber);
        }
      }
    });
  },

  methods: {
    ...mapActions("invoice", ["getUnpaidMonthlyInvoice"]),

    showUnpaidInvoiceNotification(invoiceNumber) {
      this.$store.dispatch("notify", {
        type: "is-info",
        duration: 15000,
        message: `
        <b class="pb-2">${this.$t(
          "invoice.unpaid_invoice_notification.info"
        )}</b> <br/> <br/>
        ${this.$t(
          "invoice.actions.invoice_link"
        )} <a href="/customer/invoices/${invoiceNumber}">${invoiceNumber}</a>
        `,
      });
    },
  },
};
</script>
