<template>
  <div class="main">
    <b-loading :active.sync="dataFetching" />

    <div class="px-5">
      <div class="columns">
        <div class="column">
          <h2>{{ $tp("admin.user.titles.user") }}</h2>

          <b-button href="#" @click.prevent="createUser" class="mb-2">
            {{ $t("admin.actions.create_user") }}
          </b-button>

          <UsersTable
            v-if="!dataFetching"
            @create-user="createUser"
            @get-data="getData"
          />
        </div>
      </div>
    </div>

    <CreateUserModal ref="userModal" @get-data="getData" />
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { isInDevEnv } from "@/utils/index";
import UsersTable from "@/components/Model/User/Tables/UsersTable.vue";
import CreateUserModal from "@/components/Model/User/Modal/CreateUserModal.vue";
export default {
  name: "AdminUser",
  components: {
    CreateUserModal,
    UsersTable,
  },
  data() {
    return {
      dataFetching: true,
    };
  },
  computed: {
    ...mapState("user", {
      config: (state) => state.usersConfig,
      newUser: (state) => state.newUser,
    }),
    user: {
      get() {
        return this.$store.state.user.user;
      },
      set(value) {
        this.$store.commit("user/setUser", value);
      },
    },
    isUserSend: {
      get() {
        return this.$store.state.user.isUserSend;
      },
      set(value) {
        this.$store.commit("user/setIsUserSend", value);
      },
    },
    companySearch: {
      get() {
        return this.$store.state.company.companySearch;
      },
      set(value) {
        this.$store.commit("company/setCompanySearch", value);
      },
    },
  },
  mounted() {
    this.config.resource_fields = {
      company: [
        "id",
        "reference",
        "name",
        "siren",
        "pennylane_id",
        "pennylane_supplier_id",
      ],
    };
    this.config.include = "roles,company";

    if (isInDevEnv()) {
      this.config.include += ",ordersCount";
    }

    this.getData();
  },
  methods: {
    ...mapActions("user", ["getUsers"]),
    ...mapActions("role", ["getRoles"]),
    getData() {
      this.dataFetching = true;
      let getRolesPromise = this.getRoles();
      let getUsersPromise = this.getUsers();

      Promise.all([getRolesPromise, getUsersPromise])
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        })
        .finally(() => {
          this.dataFetching = false;
        });
    },
    createUser(user) {
      if (user?.reference) {
        this.user = user;
      } else {
        this.user = this.newUser;
        this.companySearch = null;
      }

      this.$refs.userModal.show();
      this.isUserSend = false;
    },
  },
};
</script>
