<template>
  <b-modal
    v-model="isModalActive"
    has-modal-card
    trap-focus
    :destroy-on-hide="false"
    aria-role="dialog"
    :aria-label="$t('order.actions.new_order')"
    :close-button-aria-label="$t('common.actions.close')"
    aria-modal
  >
    <article
      class="approveCard card is-flex is-flex-direction-column is-align-items-center is-justify-content-center my-2 has-border-none py-6"
    >
      <div class="card-content has-text-centered pb-4">
        <ul>
          <li>
            <h4>
              <slot name="title">
                {{
                  $t("order.notifications.create_order_for_site", {
                    website: website.domain,
                  })
                }}
              </slot>
            </h4>
          </li>
        </ul>
      </div>
      <div class="is-flex is-flex-direction-column">
        <ButtonRouterLink
          size="small"
          :link="`${homepage}/website/${website.id}/new-order`"
          :text="$t('common.words.yes')"
          color="pink"
          :disabled="disabled"
        />
      </div>
      <span style="margin-bottom: 1rem"></span>
      <div
        v-if="$route.fullPath != `${homepage}/websites`"
        style="margin-bottom: 1rem"
      >
        <div class="is-flex is-flex-direction-column">
          <ButtonRouterLink
            size="small"
            :link="`${homepage}/websites`"
            :text="$t('order.actions.no_order_for_other_site')"
            color="blue"
            :disabled="disabled"
          />
        </div>
      </div>
      <div class="is-flex is-flex-direction-column">
        <ButtonRouterLink
          size="small"
          :link="`${homepage}/new-website`"
          :text="$t('order.actions.no_create_other_site')"
          color="blue"
          :disabled="disabled"
        />
      </div>
    </article>
  </b-modal>
</template>
<script>
import ButtonRouterLink from "@/components/UI/buttons/ButtonRouterLink";
import { mapState } from "vuex";
export default {
  name: "NewOrderConfirmationModal",
  components: {
    ButtonRouterLink,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    website: {
      require: true,
    },
  },

  data() {
    return {};
  },

  mounted() {},

  computed: {
    ...mapState("auth", ["homepage"]),
    isModalActive: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },

  methods: {},
};
</script>
<style scoped>
.is-spinning {
  position: relative;
  top: 3px;
}
</style>
