<template>
  <main class="columns is-desktop">
    <div class="column is-half mx-auto is-flex is-align-items-center">
      <div
        class="registerForm m-auto is-fullwidth is-flex is-flex-direction-column is-justify-content-space-evenly px-3 py-4"
      >
        <router-link to="/login">
          <img
            src="/img/logo-skribix.png"
            :alt="$t('common.img_alts.skribix_logo')"
            class="mb-2"
          />
        </router-link>

        <h2 v-if="canSubscribe" class="pb-2">
          {{ $t("skribix.hero.join_community") }}
        </h2>

        <div v-if="!canSubscribe" class="">
          <p class="has-text-pink is-size-4">
            {{ $t("auth.register.as.writer.descriptions.no_writers_accepted") }}
          </p>

          <div
            class="is-flex is-justify-content-space-between is-align-items-center mt-4"
          >
            <router-link
              to="/login"
              class="btn has-text-blue is-outlined is-underlined"
            >
              {{ $t("forms.candidate.have_account") }}
            </router-link>
          </div>
        </div>

        <CandidateRegistrationForm v-if="canSubscribe" />
      </div>
    </div>
  </main>
</template>
<script>
import CandidateRegistrationForm from "@/components/Model/Guest/CandidateRegistrationForm.vue";

export default {
  name: "CandidateRegistrationView",
  title: "metadata.title.auth.client_registration",
  components: { CandidateRegistrationForm },
  data() {
    return {
      canSubscribe: process.env.VUE_APP_CANDIDATE_SUBSCRIPTION !== "false",
    };
  },
  mounted() {
    if (!this.canSubscribe && this.$route.query.force !== undefined) {
      this.canSubscribe =
        btoa(
          prompt(this.$t("forms.candidate.enter_password_to_register"), "")
        ) === "aEqjKVBDOE84MEx1";
    }
  },
};
</script>
<style scoped>
.registerForm .image {
  width: 20vh;
  height: 20vh;
}

@media (min-width: 768px) {
  .registerForm {
    min-height: 70%;
  }
  .whiteBackground {
    width: 100%;
    min-height: max-content;
  }
}
</style>
