<template>
  <div>
    <div>
      <b-steps
        v-if="isLoading"
        v-model="activeStep"
        :animated="false"
        :class="{ mainFormView: !isBriefOrderView }"
      >
        <div
          :class="{
            'section columns is-flex is-flex-wrap-wrap py-8': !isBriefOrderView,
          }"
        >
          <section
            v-if="!isBriefOrderView"
            class="sectionFormView column is-12-mobile is-12-tablet is-12-desktop is-2-widescreen is-2-fullhd"
          >
            <div class="buttons">
              <b-step-item
                v-for="(step, index) in steps"
                :key="index"
                :visible="step.visible"
                class="navButton is-flex is-flex-direction-column is-fullwidth is-align-items-flex-start"
              >
                <b-button
                  v-if="step.isStepVisible"
                  :disabled="step.isStepDisabled"
                  :class="{
                    'is-pink is-inverted':
                      !step.isStepDisabled && !step.isStepCompleted,
                  }"
                  class="button is-large has-border-none is-fullwidth is-flex is-flex-direction-column is-align-items-flex-start"
                >
                  <span> {{ step.label }} </span>

                  <div
                    class="is-flex is-flex-wrap-wrap is-align-items-center is-justify-content-flex-start"
                  >
                    <div v-for="element in step.step_count" :key="element">
                      <IconCircle
                        v-if="step.visible"
                        :color="
                          !step.isStepCompleted &&
                          !step.isStepDisabled &&
                          (activeStep + 1 === element + index ||
                            activeStep >= element + index)
                            ? 'pink'
                            : !step.isStepCompleted &&
                              !step.isStepDisabled &&
                              activeStep + 1 < element + index
                            ? 'pink-light'
                            : !step.isStepDisabled && step.isStepCompleted
                            ? 'blue'
                            : 'grey-light'
                        "
                      />
                    </div>
                    <p
                      class="body2 bold mx-1"
                      :class="{
                        'has-text-blue-light': !step.isStepDisabled,
                        'has-text-grey-light': step.isStepDisabled,
                      }"
                    >
                      {{ step.duration }}
                    </p>
                  </div>
                </b-button>
              </b-step-item>
            </div>
          </section>
          <section
            :class="{
              'column is-12-mobile is-12-tablet is-12-desktop is-9-widescreen is-9-fullhd mb-4':
                !isBriefOrderView,
            }"
          >
            <div class="is-flex is-flex-direction-column is-fullheight">
              <ul class="titleFormStep" v-if="!isBriefOrderView">
                <li>
                  <div v-if="steps[activeStep].title">
                    <h2>
                      {{ steps[activeStep].title }}
                    </h2>
                  </div>
                  <p class="body1">
                    {{ steps[activeStep].description }}
                  </p>
                </li>
              </ul>
              <form
                class="my-auto form"
                v-for="(element, index) in steps[activeStep].content"
                :key="index"
              >
                <h4
                  v-if="element.title"
                  :class="{ 'mt-4': !element.isTitleOrderBrief }"
                >
                  {{ element.title }}
                </h4>

                <p v-if="element.description" class="body1">
                  {{ element.description }}
                </p>

                <component
                  :key="activeStep"
                  :is="element.name"
                  :indexStep="activeStep"
                  :indexField="index"
                  :field="element"
                  :isSmallField="element.small"
                  :isNextStep="isNextStep"
                  @save-result="saveResult"
                  ref="step"
                  class="is-flex is-flex-direction-column is-justify-content-space-evenly"
                  :class="{
                    'mt-4': !element.isBriefEditor,
                    'mt-3': element.isBriefEditor,
                  }"
                />
              </form>

              <article
                v-if="isBrief && steps[activeStep].isCompany"
                class="card is-flex py-2 my-2 has-border-none has-text-blue has-background-blue-border mb-4"
              >
                <div class="card-content pr-0">
                  <figure class="image is-24x24">
                    <img
                      src="/img/svg/icon_info.svg"
                      :alt="$t('forms.candidate.information_icon')"
                      :title="$t('forms.candidate.information_icon')"
                    />
                  </figure>
                </div>
                <div class="card-content">
                  <p class="body1 has-text-blue">
                    {{
                      $t(
                        "users.candidate.form.descriptions.find_this_information_again"
                      )
                    }}
                  </p>
                </div>
              </article>
            </div>
          </section>
        </div>

        <template
          v-if="customNavigation && !isBriefOrderView"
          #navigation="{ previous, next }"
        >
          <footer
            class="footerFormView has-background-white is-flex is-flex-wrap-wrap is-flex-direction-column is-justify-content-center"
          >
            <div
              class="footerButtons is-flex is-flex-wrap-wrap is-justify-content-space-between"
            >
              <b-button
                inverted
                type="is-blue"
                icon-pack="fas"
                icon-left="arrow-left"
                class="button is-large has-border-blue has-hover-bottom ml-2"
                :disabled="previous.disabled || isSaving"
                @click.prevent="goPrevious(previous)"
              >
                {{ $t("forms.candidate.previous") }}
              </b-button>

              <ButtonSaveAndContinueLater
                class="is-hidden-mobile"
                :disabled="isSaving"
                @save-draft="saveDraft"
              />

              <b-button
                v-if="!next.disabled"
                inverted
                type="is-pink"
                icon-pack="fas"
                icon-right="arrow-right"
                class="button is-large has-border-pink has-hover-bottom mr-2"
                :disabled="
                  next.disabled || isSaving || isActionButtonDisabledTemp
                "
                @click.prevent="goNext(next)"
              >
                {{ $t("forms.candidate.next") }}
              </b-button>

              <b-button
                v-else
                inverted
                type="is-pink"
                class="button is-large has-border-pink has-hover-bottom mr-2"
                :disabled="isSaving || isActionButtonDisabledTemp"
                @click.prevent="submitCandidate()"
              >
                <span v-if="candidateProfile.preferences.status === 2">
                  {{ $t("common.actions.finish") }}
                </span>
                <span v-else>{{
                  $t("forms.candidate.submit_application")
                }}</span>
              </b-button>
            </div>
          </footer>
        </template>
      </b-steps>
    </div>
    <section v-if="isCharging">
      <b-loading v-model="isCharging" />
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  prepareTextsUploadValue,
  prepareAvatarUploadValue,
  prepareSubjectsValue,
} from "@/utils/candidate";
import { CANDIDATE_STATUSES } from "@/utils/constants";
import ButtonSaveAndContinueLater from "@/components/UI/buttons/ButtonSaveAndContinueLater";

// composants New Order
import PersonnalInformation from "@/components/Model/Form/CandidateFormItem/PersonnalInformationFormItem";
import InvoicesInformation from "@/components/Model/Form/CandidateFormItem/InvoicesInformationFormItem";
import ProfilInformation from "@/components/Model/Form/CandidateFormItem/ProfilFormItem";
import FormationExperience from "@/components/Model/Form/CandidateFormItem/FormationExperienceFormItem";
import FormationInformation from "@/components/Model/Form/CandidateFormItem/FormationFormItem";
import ProfilThematic from "@/components/Model/Form/CandidateFormItem/ProfilThematicFormItem";
import FormationSEO from "@/components/Model/Form/CandidateFormItem/FormationSEOFormItem";
import FormationRedactionWork from "@/components/Model/Form/CandidateFormItem/FormationRedactionWorkFormItem";
import FormationRedactionQuantityWord from "@/components/Model/Form/CandidateFormItem/FormationRedactionQuantityWordFormItem";
import TypeTexts from "@/components/Model/Form/CandidateFormItem/TypeTextsFormItem";
import ProfilOption from "@/components/Model/Form/CandidateFormItem/ProfilOptionFormItem";
import ProfilLanguage from "@/components/Model/Form/CandidateFormItem/ProfilLanguageFormItem";
import ProfilTextExample from "@/components/Model/Form/CandidateFormItem/ProfilTextExample.vue";
import Contract from "@/components/Model/Form/CandidateFormItem/ContractFormItem";
import CreateWriterAccount from "@/components/Model/Form/CandidateFormItem/CreateWriterAccount";

// composants Template
import TemplateSelect from "@/components/Model/Form/TemplateFormItem/TemplateSelect";
import TemplateCustomSelect from "@/components/Model/Form/TemplateFormItem/TemplateCustomSelect";
import TemplateConstruct from "@/components/Model/Form/TemplateFormItem/TemplateConstructFormItem";
import TemplateSkribixSelect from "@/components/Model/Form/TemplateFormItem/TemplateSkribixSelect";

export default {
  name: "FormStepsCandidate",
  props: ["isNewCandidate", "isLoading"],
  components: {
    ProfilInformation,
    FormationExperience,
    FormationInformation,
    FormationSEO,
    FormationRedactionWork,
    FormationRedactionQuantityWord,
    ProfilThematic,
    ProfilOption,
    ProfilLanguage,
    PersonnalInformation,
    InvoicesInformation,
    TemplateSelect,
    TemplateCustomSelect,
    TemplateConstruct,
    TemplateSkribixSelect,
    ButtonSaveAndContinueLater,
    Contract,
    CreateWriterAccount,
    TypeTexts,
    ProfilTextExample,
  },
  data() {
    return {
      customNavigation: true,
      isBriefOrderView: false,
      isTemplateConstruct: false,
      isNextStep: false,
      isBrief: false,
      isSaving: false,
      isCharging: false,
      isActionButtonDisabledTemp: false,
    };
  },
  watch: {
    activeStep: function (newValue) {
      this.steps.forEach(function (element, index) {
        if (
          index + element.step_count < newValue + 1 &&
          element.isStepValid === true
        ) {
          element.isStepCompleted = true;
        } else if (index > newValue) {
          element.isStepDisabled = true;
          element.isStepCompleted = false;
        } else {
          element.isStepDisabled = false;
          element.isStepCompleted = false;
        }
      });

      this.isTemplateConstruct =
        this.steps[newValue].content[0].name === "TemplateConstruct";
    },
  },
  created() {
    this.getActiveStepFromLocalStorage();
    this.getData();
  },
  computed: {
    ...mapGetters("auth", [
      "admin",
      "writer",
      "client",
      "editorManager",
      "corrector",
      "candidate",
    ]),
    activeStep: {
      get() {
        return this.$store.state.wizard.activeStep;
      },
      set(value) {
        this.$store.commit("wizard/setActiveStep", value);
        this.updateActiveStepInLocalStorage();
      },
    },

    steps: {
      get() {
        return this.$store.state.wizard.steps;
      },
      set(value) {
        this.$store.commit("wizard/setSteps", value);
      },
    },

    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },
  },
  methods: {
    ...mapActions("user", ["editCandidateProfile"]),

    getData() {
      this.isCharging = true;

      return new Promise((resolve) => {
        let uri = window.location.search.substring(1);
        let params = new URLSearchParams(uri);
        const eversignParam = params.get("eversign");

        if (
          this.candidateProfile.preferences.status ===
            CANDIDATE_STATUSES.AWAITING_CONTRACT &&
          !this.writer
        ) {
          if (eversignParam) {
            this.activeStep = 14;

            if (eversignParam === "declined") {
              this.activeStep = 13;
            }
          } else {
            this.activeStep = 13;
          }
        } else if (this.writer) {
          if (eversignParam) {
            this.activeStep = 13;

            if (eversignParam === "declined") {
              this.activeStep = 12;
            }
          }
        }

        if (this.candidateProfile.preferences.contract_signed) {
          this.activeStep = 14;
        }

        this.isCharging = false;

        resolve();
      });
    },

    goPrevious(previous) {
      previous.action();
    },

    async goNext(next) {
      if (this.isActionButtonDisabledTemp) {
        return;
      }

      if (this.$refs.step[0].fieldsUserProfile) {
        await this.$refs.step[0].validStep(
          this.$refs.step[0].fieldsUserProfile
        );

        if (this.$refs.step[0].fieldsUserProfile.isStepValid) {
          this.goNextPromise(next);
        }
      } else {
        this.goNextPromise(next);
      }
    },

    goNextPromise(next) {
      this.isActionButtonDisabledTemp = true;

      if ((this.activeStep === 11 && this.writer) || this.activeStep === 12) {
        this.getSaveCandidateHandler();
      }

      if (
        this.$refs.step[0].fieldsUserProfile &&
        this.$refs.step[0].fieldsUserProfile.isStepValid === true
      ) {
        next.action();
        this.isNextStep = true;
      } else {
        next.action();
        this.isNextStep = false;
      }

      setTimeout(() => {
        this.isActionButtonDisabledTemp = false;
      }, 1000);
    },

    async saveDraft() {
      this.getSaveCandidateHandler(true);
    },

    previousStep() {
      this.activeStep = this.activeStep - 1;
    },

    closeTemplateConstruct() {
      this.isTemplateConstruct = false;
      this.activeStep = 1;
    },

    validTemplateConstruct() {
      this.isTemplateConstruct = false;

      if (this.isNewOrder) {
        this.activeStep = 9;
      } else if (this.isBrief) {
        this.activeStep = 10;
      }
    },

    // validation et sauvegarde des données au fur et à mesure
    saveResult(newValue) {
      this.commitValue(
        newValue.index,
        newValue.index_field,
        newValue.value_name,
        newValue.value
      );

      let step = this.steps[newValue.index].content[newValue.index_field];

      if (newValue.value_type || newValue.value_type === 0) {
        step.value_type = newValue.value_type;
      }

      step.hasError = Boolean(newValue.hasError);
    },

    commitValue(index, index_field, name, value) {
      this.steps[index].content[index_field].value = value;
    },

    validStep() {
      let valideForm = true;

      this.steps[this.activeStep].inputElements.forEach((element) => {
        if (element.required !== true) {
          return;
        }

        if (
          (Array.isArray(element.value) &&
            (element.value.every((elem) => elem === null) ||
              element.value.length === 0 ||
              element.hasError)) ||
          (element.value &&
            typeof element.value === "object" &&
            Object.keys(element.value)?.length === 0) ||
          !element.value ||
          element.value === " " ||
          element.hasError
        ) {
          valideForm = false;
        }
      });

      this.steps[this.activeStep].isStepValid = valideForm;
    },

    async submitCandidate() {
      if (
        this.$refs.step[0].validStep &&
        this.$refs.step[0].fieldsUserProfile
      ) {
        await this.$refs.step[0].validStep(
          this.$refs.step[0].fieldsUserProfile
        );

        if (!this.$refs.step[0].fieldsUserProfile.isStepValid) {
          return;
        }
      }

      this.getSaveCandidateHandler();
      this.deleteActiveStepFromLocalStorage();
    },

    getSaveCandidateHandler(asDraft = false) {
      this.isSaving = true;

      this.editCandidateProfile(asDraft)
        .then((response) => {
          let candidateData = response.data.candidate;

          candidateData.preferences.subjects = prepareSubjectsValue(
            candidateData.preferences.subjects
          );

          candidateData.textsUpload = prepareTextsUploadValue(
            candidateData.texts
          );

          candidateData.avatarUpload = prepareAvatarUploadValue(
            candidateData.avatar
          );

          this.candidateProfile = candidateData;
          this.$store.commit("user/setCandidateProfile", candidateData);
          this.$store.commit("auth/setUser", candidateData);

          if (asDraft) {
            this.$store.dispatch("notify", {
              type: "is-info",
              message: this.$t("forms.candidate.info_saved"),
            });
          }

          this.$emit("after-save", candidateData);
        })
        .catch((e) => {
          this.handleValidationErrorsFromServer();
          this.$store.dispatch("fireError", e);
        })
        .finally(() => {
          this.isSaving = false;
        });
    },

    handleValidationErrorsFromServer() {
      this.$refs.step[0].validStep(this.$refs.step[0].fieldsUserProfile);
    },

    updateActiveStepInLocalStorage() {
      localStorage.setItem(
        "activeStep" + this.candidateProfile.reference,
        this.activeStep
      );
    },

    getActiveStepFromLocalStorage() {
      let activeStep = localStorage.getItem(
        "activeStep" + this.candidateProfile.reference
      );

      if (activeStep) {
        this.activeStep = parseInt(activeStep);
      }
    },

    deleteActiveStepFromLocalStorage() {
      localStorage.removeItem("activeStep" + this.candidateProfile.reference);
    },
  },
};
</script>
<style scoped>
.footerButtons {
  height: 80%;
}

.footerFormView {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  font-family: Athletics !important;
  z-index: 4;
}

.mainFormView nav {
  display: none;
}

.navButton p {
  font-family: Athletics !important;
}

.navButton {
  width: 100% !important;
}
.navButton.button.is-inverted {
  text-align: left !important;
  pointer-events: none;
  box-shadow: none !important;
}
.summaryCard {
  background: white;
}
.jagged-bottom {
  position: relative;
}
.jagged-bottom:after {
  background: white;
  content: "";
  height: 2vw;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  clip-path: polygon(
    0 0,
    5% 50%,
    10% 0,
    15% 50%,
    20% 0,
    25% 50%,
    30% 0,
    35% 50%,
    40% 0,
    45% 50%,
    50% 0,
    55% 50%,
    60% 0,
    65% 50%,
    70% 0,
    75% 50%,
    80% 0,
    85% 50%,
    90% 0,
    95% 50%,
    100% 0
  );
}
</style>

<style>
.mainFormView .form .b-checkbox.checkbox.button,
.mainFormView .form .b-radio.radio.button {
  padding: 2em 1em;
}
.mainFormView .form .b-numberinput .button i {
  color: #ebebeb;
}
.mainFormView .form .b-numberinput .button.is-white[disabled] {
  border: transparent;
}
.mainFormView .isSmallField .select,
.mainFormView .isSmallField .select select {
  width: 100%;
}
.mainFormView .form .isheightMaxContent .b-radio.radio.button {
  min-height: 12vh;
}
.mainFormView nav.steps {
  display: none;
}

@media (min-width: 1216px) {
  .mainFormView {
    height: 90vh;
  }
  .mainFormView .sectionFormView {
    min-height: 80vh;
  }
  .footerFormView {
    height: 8vh;
    min-height: 60px;
  }
  .mainFormView .form .b-checkbox.checkbox.button,
  .mainFormView .form .b-radio.radio.button {
    min-height: 6vh;
  }
  .list {
    min-height: 50vh;
  }
  .boxButton p,
  .boxButton h5 {
    text-align: left !important;
  }
}
@media (max-width: 768px) {
  .mainFormView {
    padding-bottom: 3em;
  }
}

.step-navigation {
  display: none;
}
.step-content {
  padding: 0 !important;
}
.b-steps .steps {
  display: none;
}
</style>
