<template>
  <div class="main">
    <div class="px-5">
      <div class="columns">
        <div class="column">
          <h2>{{ $tp("thematic.titles.thematic") }}</h2>

          <b-button href="#" @click.prevent="createThematic" class="mb-2">
            {{ $t("admin.actions.add_thematic") }}
          </b-button>

          <ThematicsTable @show-popup="$refs.createModal.show()" />
        </div>
      </div>
    </div>

    <CreateThematicModal ref="createModal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ThematicsTable from "@/components/Model/Thematics/ThematicsTable";
import CreateThematicModal from "@/components/Model/Thematics/Modal/CreateThematicModal";

export default {
  title: "metadata.title.admin.thematics",
  name: "AdminThematics",
  components: { CreateThematicModal, ThematicsTable },
  data() {
    return {};
  },
  created() {
    this.config.include += "parent,";
  },
  computed: {
    ...mapState("thematic", {
      config: (state) => state.thematicsConfig,
    }),
  },
  methods: {
    createThematic() {
      this.$store.commit("thematic/setDefaultThematic");

      this.$refs.createModal.show();
    },
  },
};
</script>
