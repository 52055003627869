function resolveKey(locales, key) {
  return typeof locales === "function" ? locales(key) : locales.resolve(key);
}

function loadLocaleMessages(locales) {
  try {
    if (window.trad?.messages) {
      return window.trad.messages;
    }
  } catch (error) {
    console.log("");
  }

  const messages = {};

  locales.keys().forEach((key) => {
    // Match the locale and subpath within the language directory
    const matched = key.match(/\.?\/([A-Za-z0-9-_]+)(\/(.+))?\.json$/i);

    if (matched && matched.length > 1) {
      const locale = matched[1];
      const subpath = matched[3] ? matched[3].replace(/\//g, ".") : ""; // Flatten the nested structure

      if (!messages[locale]) {
        messages[locale] = {};
      }

      // Ensure nested objects are created properly
      const parts = subpath.split(".");
      let current = messages[locale];

      for (let i = 0; i < parts.length - 1; i++) {
        if (!current[parts[i]]) {
          current[parts[i]] = {};
        }
        current = current[parts[i]];
      }

      if (subpath) {
        current[parts[parts.length - 1]] = resolveKey(locales, key);
      } else {
        messages[locale] = resolveKey(locales, key);
      }
    }

    try {
      window.trad = { messages };
    } catch (error) {
      console.log(" ");
    }

    return messages;
  });

  return messages;
}

module.exports = loadLocaleMessages;
