<template>
  <b-button
    v-if="field.isEditable && field.patchAction"
    class="is-small has-border-none ml-2"
    :class="{
      'has-text-blue': !saving,
    }"
    :loading="saving"
    @click.prevent="saveComponent"
  >
    <IconSave class="iconPen" />
  </b-button>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    before: {
      type: Function,
    },
    callback: {
      type: Function,
    },
  },
  data() {
    return {
      saving: false,
    };
  },
  methods: {
    async saveComponent() {
      if (this.field.patchAction) {
        this.saving = true;

        if (this.before) {
          this.before();
          await setTimeout(this.before, 100);
        }

        this.field
          .patchAction({
            key: this.field.value_name,
            value: this.field.value,
            additionalData: this.field.additionalData || {},
          })
          .then(this.callback)
          .then(() => {
            this.$store.dispatch("notify", {
              type: "is-success",
              message: this.$t("text.ideal_title_length", {
                label: this.field.label,
              }),
            });
          })
          .finally(() => {
            this.saving = false;
          });
      }
    },
  },
};
</script>
