var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.field.isEditableFormat && !_vm.isEditing)?_c('div',[_c('OrderBriefArticle',{attrs:{"isEditing":_vm.isEditing,"field":_vm.field,"value":_vm.field.value},on:{"start-editing":function($event){_vm.isEditing = true}}}),_c('CardValidationField',{attrs:{"field":_vm.field.isValid,"is-next-step":_vm.isNextStep,"message":_vm.$t('wizard.message.required')}})],1):_vm._e(),(!_vm.field.isEditableFormat || (_vm.field.isEditableFormat && _vm.isEditing))?_c('b-field',{class:{
      'is-danger': _vm.hasError,
      'is-flex is-align-items-center input-edit is-justify-content-space-between':
        _vm.field.isUserView,
      'mt-2': true,
    },attrs:{"custom-class":_vm.field.isUserView && 'is-flex is-flex-grow-1 mr-2',"label":_vm.field.label ? _vm.field.label : '',"message":_vm.hasError ? _vm.field.hasErrorMessage : ''}},[_c('div',{class:{
        'is-flex is-align-items-center': _vm.field.isUserView,
      }},[_c('b-switch',{model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._v(" "+_vm._s(_vm.selected ? _vm.$t("common.words.yes") : _vm.$t("common.words.no"))+" ")]),(_vm.field.isEditable && _vm.field.isUserView)?_c('PartialSaveFormValueButton',{attrs:{"field":_vm.field,"before":_vm.persistValue,"callback":_vm.stopEditing}}):_vm._e()],1)]):_vm._e(),(_vm.hasError && _vm.field.hasErrorMessage)?_c('CardValidationField',{attrs:{"is-next-step":_vm.isNextStep,"message":_vm.field.hasErrorMessage}}):_vm._e(),(!_vm.selected || _vm.selected === ' ')?_c('CardValidationField',{attrs:{"field":_vm.field.isValid,"is-next-step":_vm.isNextStep,"message":_vm.$t('wizard.message.required')}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }