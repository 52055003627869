<template>
  <div>
    <div v-if="field.isEditableFormat && !isEditing">
      <OrderBriefArticle
        :isEditing="isEditing"
        :field="field"
        :value="field.value"
        @start-editing="isEditing = true"
      />

      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>

    <b-field
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
      :custom-class="field.isUserView && 'is-flex is-flex-grow-1 mr-2'"
      :label="field.label ? field.label : ''"
      :class="{
        'is-danger': hasError,
        'is-flex is-align-items-center input-edit is-justify-content-space-between':
          field.isUserView,
        'mt-2': true,
      }"
      :message="hasError ? field.hasErrorMessage : ''"
    >
      <div
        :class="{
          'is-flex is-align-items-center': field.isUserView,
        }"
      >
        <b-switch v-model="selected">
          {{ selected ? $t("common.words.yes") : $t("common.words.no") }}
        </b-switch>

        <PartialSaveFormValueButton
          v-if="field.isEditable && field.isUserView"
          :field="field"
          :before="persistValue"
          :callback="stopEditing"
        />
      </div>
    </b-field>

    <CardValidationField
      v-if="hasError && field.hasErrorMessage"
      :is-next-step="isNextStep"
      :message="field.hasErrorMessage"
    />
    <CardValidationField
      v-if="!selected || selected === ' '"
      :field="field.isValid"
      :is-next-step="isNextStep"
      :message="$t('wizard.message.required')"
    />
  </div>
</template>
<script>
import CardValidationField from "@/components/UI/card/CardValidationField";
import OrderBriefArticle from "@/components/Model/Order/OrderBriefArticle";

export default {
  name: "BriefSwitch",
  props: ["field", "indexStep", "indexField", "isNextStep"],
  components: { CardValidationField, OrderBriefArticle },
  data() {
    return {
      isEditing: false,
      selected: this.field.value ? this.field.value : null,
      hasError: false,
    };
  },
  watch: {
    selected: function (newValue) {
      this.hasError = false;

      if (this.field.regex && !newValue.match(this.field.regex)) {
        this.hasError = true;
      }

      if (!this.field.isUserView) {
        this.$emit("save-result", {
          index: this.indexStep,
          index_field: this.indexField,
          value_name: this.field.value_name,
          value: Boolean(newValue),
          hasError: this.hasError,
        });
      }
    },
  },
  methods: {
    stopEditing() {
      this.isEditing = false;
    },

    saveComponent() {
      if (this.field.isUserView) {
        this.persistValue();
      }
    },

    persistValue() {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: this.selected,
        hasError: this.hasError,
        model: this.field.model,
      });
    },
  },
};
</script>
