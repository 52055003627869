<template>
  <div>
    <div v-if="field.isEditableFormat && !isEditing">
      <OrderBriefArticle
        :isEditing="isEditing"
        :field="field"
        :value="field.value"
        @start-editing="isEditing = true"
      />

      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>
    <b-field
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
      :custom-class="field.isUserView && 'is-flex is-flex-grow-1 mr-2'"
      :label="field.label ? field.label : ''"
      :class="{
        'is-danger': hasError,
        'is-flex is-align-items-center input-edit': field.isUserView,
      }"
      :message="hasError ? field.hasErrorMessage : ''"
    >
      <div
        :class="{
          'is-flex is-align-items-center is-flex-grow-5': field.isUserView,
        }"
      >
        <VuePhoneNumberInput
          v-model="selected"
          type="text"
          :placeholder="field.placeholder ? field.placeholder : ''"
          class="phone-number"
          :class="{
            'b-input mt-2': !field.isUserView,
            'width-100000': field.isUserView,
          }"
          :show-code-on-list="true"
          :default-country-code="countryCode"
          @update="onUpdate"
          :translations="{
            countrySelectorLabel: $t(
              'forms.personal.fields.country_code.label'
            ),
            countrySelectorError: $t(
              'forms.personal.fields.country_code.messages.select_error'
            ),
            phoneNumberLabel: $ts('forms.personal.fields.phone'),
            example: $ts('forms.example') + ':',
          }"
          :disabled="Boolean(disabled)"
        />
        <PartialSaveFormValueButton
          v-if="field.isEditable && field.isUserView"
          :field="field"
          :before="persistValue"
          :callback="stopEditing"
        />
      </div>
    </b-field>

    <CardValidationField
      v-if="hasError && field.hasErrorMessage"
      :is-next-step="isNextStep"
      :message="field.hasErrorMessage"
    />
    <CardValidationField
      v-if="!selected || selected === ' '"
      :field="field.isValid"
      :is-next-step="isNextStep"
      :message="$t('wizard.message.required')"
    />
  </div>
</template>
<script>
import CardValidationField from "@/components/UI/card/CardValidationField";
import OrderBriefArticle from "@/components/Model/Order/OrderBriefArticle";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  name: "Phone-input",
  props: ["field", "indexStep", "indexField", "isNextStep", "disabled"],
  components: { CardValidationField, OrderBriefArticle, VuePhoneNumberInput },

  data() {
    const phoneNumber = this.field.value
      ? this.removePhoneCountryCode(this.field.value)
      : null;

    return {
      isEditing: false,
      selected: phoneNumber,
      countryCode: this.field.countryCode
        ? this.field.countryCode === "33"
          ? "FR"
          : this.field.countryCode
        : "FR",
      hasError: false,
      phoneNumber: phoneNumber,
    };
  },

  watch: {
    selected: function (newValue) {
      this.hasError = false;

      if (this.field.regex && !newValue.match(this.field.regex)) {
        this.hasError = true;
      }

      if (!this.field.isUserView) {
        this.$emit("save-result", {
          index: this.indexStep,
          index_field: this.indexField,
          value_name: this.field.value_name,
          value: this.phoneNumber,
          hasError: this.hasError,
        });

        this.$emit("save-result", {
          index: this.indexStep,
          index_field: this.indexField,
          value_name: "country_code",
          value: this.countryCode,
          hasError: this.hasError,
        });
      }
    },
  },
  methods: {
    stopEditing() {
      this.isEditing = false;
    },

    saveComponent() {
      if (this.field.isUserView) {
        this.$emit("save-result", {
          index: this.indexStep,
          index_field: this.indexField,
          value_name: this.field.value_name,
          model: this.field.model,
          value: this.phoneNumber,
          hasError: this.hasError,
        });

        this.$emit("save-result", {
          index: this.indexStep,
          index_field: this.indexField,
          value_name: "country_code",
          value: this.countryCode,
        });
      }
    },

    onUpdate(value) {
      this.hasError = !value.isValid;
      this.phoneNumber = value.formatInternational;
      this.countryCode = value.countryCode;
    },

    removePhoneCountryCode(phone) {
      if (phone && phone.includes("+")) {
        return phone
          .replace(/^\+[0-9]+/, "")
          .trim()
          .replace(/ /g, "");
      }

      return phone;
    },

    persistValue() {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        value: this.phoneNumber,
        hasError: this.hasError,
        model: this.field.model,
        additionalData: {
          country_code: this.countryCode,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .phone-number {
  input {
    background-color: #ffffff !important;
    border-color: #f6f6f9 !important;
    border-radius: 8px !important;
    color: #202560 !important;
  }

  input:hover {
    background-color: #ffffff !important;
    border-color: #202560 !important;
    border-radius: 8px !important;
    color: #202560 !important;
  }

  label {
    color: #202560 !important;
  }

  .country-selector__input {
    &,
    &:hover {
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
  .input-tel__input {
    &,
    &:hover {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}
</style>
