export default {
  parentsThematics(state) {
    let parentsThematics = [];
    state.thematics.map((parent) => {
      if (!parent.parent_id) {
        parentsThematics.push(parent);
      }
    });

    return parentsThematics;
  },
  subThematics(state, getters, rootState) {
    let subThematics = [];

    if (rootState.website.website.thematic_id.length > 0) {
      state.thematics.map((parent) => {
        if (
          rootState.website.website.thematic_id.includes(parent.parent_id) ||
          rootState.website.website.thematic_id.includes(parent.id)
        ) {
          subThematics.push(parent);
        }
      });
    }
    return subThematics;
  },

  subCategories(state, getters, rootState) {
    const websiteThematicIds = rootState.website.website.thematic_id;

    if (websiteThematicIds.length === 0) {
      return {};
    }

    return state.thematics.reduce((categories, thematic) => {
      if (websiteThematicIds.includes(thematic.parent_id)) {
        if (!categories[thematic.parent_id]) {
          const parent = state.thematics.find(
            (el) => el.id === thematic.parent_id
          );
          categories[thematic.parent_id] = {
            id: thematic.parent_id,
            name: parent ? parent.name : undefined,
            children: [],
          };
        }

        categories[thematic.parent_id].children.push(thematic);
      }

      return categories;
    }, {});
  },
};
