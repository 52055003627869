<template>
  <article
    class="approveCard card is-flex is-flex-direction-column is-align-items-center is-justify-content-center my-2 has-border-none py-6"
  >
    <div class="card-content has-text-centered pb-4">
      <ul>
        <li>
          <h4>
            {{ $t("forms.wizard.template.construct.confirms.create.title") }}
          </h4>
        </li>
        <li>
          <p class="body1">
            {{ $t("forms.wizard.template.construct.confirms.create.info") }}
          </p>
        </li>
      </ul>
    </div>
    <div class="buttons is-flex is-flex-direction-column">
      <b-field>
        <b-input
          v-model="templateName"
          type="text"
          :placeholder="
            $t('forms.wizard.template.construct.confirms.create.fields.name')
          "
          class="b-input mt-2"
        />
      </b-field>

      <b-button
        type="is-pink"
        inverted
        class="button is-large has-border-pink has-hover-bottom"
        @click.prevent="createTemplate"
      >
        {{
          $t("forms.wizard.template.construct.confirms.create.actions.submit")
        }}
      </b-button>

      <template v-if="false">
        <b-button
          type="is-pink"
          inverted
          class="button is-large has-border-pink has-hover-bottom"
          @click.prevent="createTemplate"
        >
          {{
            isExistingTemplate
              ? $t("common.words.yes")
              : $t(
                  "forms.wizard.template.construct.confirms.create.actions.submit"
                )
          }}
        </b-button>
        <b-button
          v-if="isExistingTemplate"
          type="is-pink"
          inverted
          class="button is-large has-border-pink has-hover-bottom"
          @click.prevent="validExistingTemplate"
        >
          {{ $t("common.words.no") }}
        </b-button>
      </template>
      <b-button
        type="is-link"
        inverted
        class="button is-large has-border-link has-hover-bottom"
        @click.prevent="close"
      >
        {{ $t("common.actions.cancel") }}
      </b-button>
    </div>
  </article>
</template>
<script>
export default {
  name: "templateCreateNameModal",
  props: ["isExistingTemplate"],
  data() {
    return {
      templateName: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    createTemplate() {
      this.$emit("create-template", this.templateName);
    },
    validExistingTemplate() {
      this.$emit("valid-existing-template");
    },
  },
};
</script>
<style scoped>
@media (min-width: 1024px) {
  .approveCard .card-content {
    width: 80%;
  }
}
</style>
