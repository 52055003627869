<template>
  <Modal ref="baseModal" isFullWidth :is-half="false">
    <template v-slot:header>
      <h3 v-if="activeTemplateData?.id">
        {{ $t("admin.template.form.titles.edit") }} : {{ templateName }}
      </h3>
      <h3 v-else>{{ $t("admin.template.form.titles.add") }}</h3>

      <CloseModalButton @click="hide" />
    </template>

    <TemplateForm @saved="saved" />
  </Modal>
</template>

<script>
import { mapState, mapActions } from "vuex";
import Modal from "@/components/UI/Modal";
import CloseModalButton from "@/components/UI/buttons/CloseModalButton";
import TemplateForm from "@/components/Model/Templates/Form/TemplateForm";

export default {
  name: "TemplateFormModal",
  components: { TemplateForm, CloseModalButton, Modal },
  data() {
    return {
      templateName: "",
    };
  },
  mounted() {
    this.templateName = this.activeTemplateData.name || "";
  },
  computed: {
    ...mapState("template", ["activeTemplateData"]),
  },
  methods: {
    ...mapActions("template", ["getTemplates"]),

    show() {
      this.$refs.baseModal.show();
    },
    hide() {
      this.$store.commit("template/setActiveTemplateData", {});
      this.$refs.baseModal.hide();
    },
    saved() {
      this.getTemplates();
      this.hide();
    },
  },
};
</script>
