<template>
  <b-navbar v-if="!orderWithFailedPayment" transparent class="py-0 px-4 navBar">
    <template #brand>
      <b-navbar-item
        tag="router-link"
        class="has-text-white is-size-4 py-0"
        :to="{ path: homepage }"
      >
        <img
          class="logoSkribix"
          src="@/assets/logo_skribix.png"
          :alt="$t('common.img_alts.skribix_logo')"
          :title="$t('common.img_alts.skribix_logo')"
        />
      </b-navbar-item>
      <ul
        class="w-full is-flex is-justify-content-flex-end is-align-items-center pl-6 is-hidden-desktop ml-auto"
      >
        <li>
          <b-dropdown aria-role="list" class="p-0" id="avatarButtonContainer">
            <template #trigger="{ active }">
              <b-button
                class="is-rounded p-0 m-0"
                :icon="active ? 'menu-up' : 'menu-down'"
              >
                <PictureProfile :user="user" />
              </b-button>
            </template>
            <b-navbar-item
              tag="router-link"
              class="itemMobileMenu has-text-blue bold"
              :to="`${homepagePrefix}/profile`"
              >{{ $t("layout.menu.profiles.profile") }}
            </b-navbar-item>
            <b-navbar-item
              class="itemMobileMenu has-text-blue bold"
              href="#"
              @click.prevent="doLogout"
              >{{ $t("auth.common.actions.logout") }}
            </b-navbar-item>
          </b-dropdown>
        </li>
      </ul>
    </template>
    <template #end>
      <div class="is-flex" v-if="admin">
        <b-navbar-item tag="router-link" :to="{ path: '/users/candidates' }"
          >{{ $t("layout.menu.users.actions.manage_candidates") }}
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :to="`${homepagePrefix}/monthly-invoices`"
          >{{ $t("layout.menu.payments.invoices.monthly_invoices_payment") }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="`${homepagePrefix}/invoices`"
          >{{ $t("layout.menu.payments.invoices.invoices_payment") }}
        </b-navbar-item>
      </div>
      <div class="is-flex" v-if="editorManager">
        <b-navbar-item tag="router-link" :to="`${homepagePrefix}/customers`"
          >{{ $t("layout.menu.lists.customers_list") }}
        </b-navbar-item>
        <b-navbar-item tag="router-link" :to="`${homepagePrefix}/writers`"
          >{{ $t("layout.menu.lists.writers_list") }}
        </b-navbar-item>
      </div>
      <div class="is-flex" v-if="writer && !candidate">
        <b-navbar-item tag="router-link" :to="`/${USER_ROLES.WRITER}/invoices`">
          {{ $t("invoice.titles.my_invoices") }}
        </b-navbar-item>
      </div>
      <div class="is-flex" v-if="client">
        <b-navbar-item tag="router-link" :to="`${homepagePrefix}/websites`">
          {{ $t("layout.menu.sites.my_sites") }}
        </b-navbar-item>

        <template v-if="clientRoleOnly">
          <b-navbar-item
            tag="router-link"
            :to="`${homepagePrefix}/favorite-writers`"
          >
            {{ $t("layout.menu.editors.my_favorites_editors") }}
          </b-navbar-item>
          <b-navbar-item tag="router-link" :to="`${homepagePrefix}/invoices`">
            <i
              v-if="unpaidMonthlyInvoice"
              class="fas fa-circle mr-1 has-text-pink"
            ></i>
            {{ $t("invoice.titles.my_invoices") }}
          </b-navbar-item>
          <b-navbar-item
            v-bind="{
              ...(mainClient
                ? {
                    to: `${homepagePrefix}/profile?tab=orders`,
                    tag: 'router-link',
                  }
                : {
                    tag: 'span',
                  }),
            }"
          >
            {{ $t("layout.menu.payments.credits.my_credits") }} :
            <CreditBalance class="ml-1" />
          </b-navbar-item>
          <b-navbar-item
            v-if="onGoingSubscriptionCount"
            :disabled="billingPortalUrlFetching"
            @click="openBillingPortal"
          >
            <b-loading :active.sync="billingPortalUrlFetching" />
            {{ $t("layout.menu.subscriptions.my_subscriptions") }}
          </b-navbar-item>
        </template>
      </div>
      <div class="is-flex" v-if="recruiter">
        <b-navbar-item tag="router-link" :to="{ path: '/users/candidates' }">
          {{ $t("layout.menu.users.actions.manage_candidates") }}
        </b-navbar-item>
      </div>
      <ul
        class="is-flex is-justify-content-center is-align-items-center pl-6 is-hidden-touch"
      >
        <li class="mr-2 has-text-white" v-if="isLocalEnv">
          {{ $t("layout.header.details.logged_in_as") }} :
          {{ user.roles.map((role) => role.name).join(", ") }}
        </li>

        <li class="mr-2" v-if="user.avatar">
          <PictureProfile class="has-border-white rounded" :user="user" />
        </li>

        <li>
          <b-navbar-dropdown
            class="button is-blue has-border-white is-flex is-justify-content-center is-align-items-center"
            :label="user.firstname"
          >
            <b-navbar-item
              tag="router-link"
              class="has-text-blue"
              :to="`${homepagePrefix}/profile`"
              >{{ $t("layout.menu.profiles.profile") }}
            </b-navbar-item>
            <b-navbar-item
              class="has-text-blue"
              href="#"
              @click.prevent="doLogout"
              >{{ $t("auth.common.actions.logout") }}
            </b-navbar-item>
          </b-navbar-dropdown>
        </li>
      </ul>
    </template>
  </b-navbar>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import { USER_ROLES } from "@/utils/constants";
import PictureProfile from "@/components/Model/User/UserAvatar";
import CreditBalance from "@/components/Model/Credit/CreditBalance";

export default {
  name: "nav-bar",
  components: { PictureProfile, CreditBalance },
  data() {
    return {
      billingPortalUrlFetching: false,
      USER_ROLES,
    };
  },
  computed: {
    ...mapState("auth", {
      user: (state) => state.user,
      homepagePrefix: (state) => state.homepage,
    }),
    ...mapState("invoice", ["unpaidMonthlyInvoice"]),
    ...mapGetters("auth", [
      "admin",
      "writer",
      "client",
      "mainClient",
      "editorManager",
      "corrector",
      "homepage",
      "recruiter",
      "candidate",
      "clientRoleOnly",
    ]),
    ...mapState("user", ["onGoingSubscriptionCount"]),
    ...mapState("order", ["orderWithFailedPayment"]),

    isLocalEnv() {
      return window.location.hostname === "localhost";
    },
  },
  mounted() {
    if (this.clientRoleOnly) {
      this.getSubscriptionsStatuses();
    }
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("user", ["getBillingPortal", "getSubscriptionsStatuses"]),

    doLogout() {
      this.logout();
      this.$router.push("/login");
    },

    openBillingPortal() {
      this.billingPortalUrlFetching = true;

      this.getBillingPortal().then((url) => {
        this.billingPortalUrlFetching = false;

        if (url) {
          window.open(url, "blank");
        }
      });
    },
  },
};
</script>
<style scoped>
.itemMobileMenu {
  padding: 1rem 1.5rem;
}
#avatarButton {
  border: none !important;
}
.logoSkribix {
  max-height: 2em !important;
}
.navBar {
  z-index: 1;
}
</style>
