<template>
  <b-modal
    v-model="isActive"
    trap-focus
    aria-modal
    has-modal-card
    aria-role="dialog"
    :aria-label="$t('order.actions.integrate_text')"
    :close-button-aria-label="$t('common.actions.close')"
    :destroy-on-hide="false"
    :can-cancel="!orderDuplicating"
  >
    <article
      class="card is-flex is-flex-direction-column is-align-items-center is-justify-content-space-evenly my-2 has-border-none py-6 m-auto"
    >
      <div class="card-content has-text-centered pb-4">
        <ul>
          <li>
            <h4>{{ $t("order.notifications.duplicate_order") }}</h4>
          </li>
          <li>
            <p class="body1 mt-2">
              {{ $t("order.actions.confirm_duplicate_order") }}
            </p>
          </li>
        </ul>
      </div>
      <div class="buttons is-flex is-flex-direction-column">
        <b-button
          type="is-pink"
          inverted
          class="button is-large has-border-pink has-hover-bottom"
          :disabled="orderDuplicating"
          @click.prevent="duplicateOrder"
        >
          {{ $t("order.actions.duplicate") }}
        </b-button>
      </div>
    </article>
  </b-modal>
</template>
<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "order-duplicate-modal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      orderDuplicating: false,
    };
  },
  methods: {
    ...mapActions("order", ["getDuplicateOrder"]),

    duplicateOrder() {
      this.orderDuplicating = true;

      this.getDuplicateOrder(this.order.reference)
        .then((response) => {
          this.$router.push(
            `${this.homepage}/website/${response.data.order.website_id}/new-order/${response.data.order.reference}`
          );
        })
        .catch((e) => {
          this.orderDuplicating = false;
          this.$store.dispatch("fireError", e);
        });
    },
  },
  computed: {
    ...mapState("auth", ["homepage"]),

    isActive: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
};
</script>
