import { render, staticRenderFns } from "./ProfilTextExample.vue?vue&type=template&id=6a331920&scoped=true"
import script from "./ProfilTextExample.vue?vue&type=script&lang=js"
export * from "./ProfilTextExample.vue?vue&type=script&lang=js"
import style0 from "./ProfilTextExample.vue?vue&type=style&index=0&id=6a331920&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6a331920",
  null
  
)

export default component.exports