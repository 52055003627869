import { render, staticRenderFns } from "./InvoicesInformationFormItem.vue?vue&type=template&id=4fe326d5&scoped=true"
import script from "./InvoicesInformationFormItem.vue?vue&type=script&lang=js"
export * from "./InvoicesInformationFormItem.vue?vue&type=script&lang=js"
import style0 from "./InvoicesInformationFormItem.vue?vue&type=style&index=0&id=4fe326d5&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fe326d5",
  null
  
)

export default component.exports