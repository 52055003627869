<template>
  <div class="main">
    <div class="px-5">
      <div class="columns">
        <div class="column">
          <h2>{{ $tp("admin.template.titles.template") }}</h2>

          <b-button href="#" @click.prevent="createTemplate" class="mb-2">
            {{ $t("admin.template.actions.add") }}
          </b-button>

          <TemplatesTable @show-form-modal="$refs.templateFormModal.show()" />
        </div>
      </div>
    </div>

    <TemplateFormModal ref="templateFormModal" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import TemplatesTable from "@/components/Model/Templates/TemplatesTable";
import TemplateFormModal from "@/components/Model/Templates/Modal/TemplateFormModal";

export default {
  title: "metadata.title.admin.templates",
  name: "AdminTemplates",
  components: { TemplatesTable, TemplateFormModal },
  computed: {
    ...mapState("template", ["activeTemplateData"]),
  },
  methods: {
    createTemplate() {
      this.$store.commit("template/resetActiveTemplateData");
      this.$refs.templateFormModal.show();
    },
  },
};
</script>
