<template>
  <article class="hero box p-4 is-pink-light my-2">
    <figure>
      <img
        src="/img/svg/avis.svg"
        :title="$t('skribix.boxes.review.img_alt')"
        :alt="$t('skribix.boxes.review.img_alt')"
      />
    </figure>
    <h4 class="has-text-pink">
      {{ $t("skribix.boxes.review.title") }}
    </h4>
    <p class="body1 has-text-pink">
      {{ $t("skribix.boxes.review.description") }}
    </p>
    <a
      class="mt-4 has-text-pink is-flex is-align-items-center"
      target="_blank"
      href="https://www.avis-verifies.com/avis-clients/skribix.com"
    >
      {{ $t("skribix.boxes.review.actions.evaluate") }}
      <IconArrowRight class="ml-2" />
    </a>
  </article>
</template>
<script>
export default {
  name: "BoxExportWordpress",
};
</script>
