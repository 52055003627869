<template>
  <article class="hero box p-4 m-0 is-white my-2">
    <figure>
      <img
        src="/img/svg/web_seo.svg"
        :title="$t('skribix.boxes.seo_webinar.img_alt')"
        :alt="$t('skribix.boxes.seo_webinar.img_alt')"
      />
    </figure>
    <h4>{{ $t("skribix.boxes.seo_webinar.title") }}</h4>
    <p class="body1">{{ $t("skribix.boxes.seo_webinar.description") }}</p>
    <a
      class="mt-4 has-text-blue-light is-flex is-align-items-center"
      href="https://webikeo.fr/chaine/skribix/"
      target="_blank"
    >
      {{ $t("skribix.boxes.seo_webinar.actions.reserve") }}
      <IconArrowRight class="ml-2" />
    </a>
  </article>
</template>
<script>
export default {
  name: "BoxSeoWebinar",
};
</script>
