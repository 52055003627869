<template>
  <div>
    <b-loading :active.sync="dataFetching" />
    <div v-if="isLoading">
      <FormStepsCandidate
        v-if="
          status === CANDIDATE_STATUSES.DRAFT ||
          status === CANDIDATE_STATUSES.AWAITING_CONTRACT
        "
        :isNewCandidate="isNewCandidate"
        :isLoading="isLoading"
        @after-save="afterSave"
      />
      <CandidatePending
        v-if="
          status === CANDIDATE_STATUSES.PENDING ||
          status === CANDIDATE_STATUSES.REJECTED
        "
        :isNewCandidate="isNewCandidate"
        :isLoading="isLoading"
      />
      <div v-if="status === CANDIDATE_STATUSES.AWAITING_TEST">
        <DashboardHeader />
        <OrdersTab class="pt-4 p-2" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import {
  prepareTextsUploadValue,
  prepareAvatarUploadValue,
  prepareSubjectsValue,
} from "@/utils/candidate";
import { CANDIDATE_STATUSES } from "@/utils/constants";
import FormStepsCandidate from "@/components/Model/Form/FormStepsCandidate";
import CandidatePending from "@/views/User/CandidatePending";
import OrdersTab from "@/components/Model/Order/OrdersTab";
import DashboardHeader from "@/components/UI/DashboardHeader";

export default {
  title: "metadata.title.form.new_candidate",
  name: "FormNewCandidate",
  components: {
    FormStepsCandidate,
    CandidatePending,
    OrdersTab,
    DashboardHeader,
  },
  data() {
    return {
      isNewCandidate: true,
      dataFetching: false,
      isLoading: false,
      status: 1,
      CANDIDATE_STATUSES,
    };
  },
  methods: {
    ...mapActions("user", ["getCandidateProfile"]),
    ...mapActions("thematic", ["getThematics"]),

    async getData() {
      this.dataFetching = true;
      this.isLoading = false;

      let getCandidateProfilePromise = this.getCandidateProfile();
      let getThematicsPromise = this.getThematics();

      Promise.all([getCandidateProfilePromise, getThematicsPromise])
        .then(() => {
          this.isLoading = true;

          this.status = this.candidateProfile.preferences.status;

          if (!this.candidateProfile.company) {
            this.candidateProfile.company = {};
          }

          this.candidateProfile.subjects = prepareSubjectsValue(
            this.candidateProfile.preferences.subjects
          );

          this.candidateProfile.textsUpload = prepareTextsUploadValue(
            this.candidateProfile.texts
          );

          this.candidateProfile.avatarUpload = prepareAvatarUploadValue(
            this.candidateProfile.avatar
          );

          let arrayThematics = [];

          this.candidateProfile.preferences.subjects.forEach((el) =>
            arrayThematics.push(el.id)
          );

          this.candidateProfile.preferences.subjects = arrayThematics;

          this.steps =
            this.candidateProfile.preferences.status ===
            CANDIDATE_STATUSES.DRAFT
              ? this.baseSteps
              : this.fullSteps;

          if (this.writer) {
            this.steps.splice(7, 1);
          }

          this.dataFetching = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    afterSave(candidate) {
      this.status = candidate.preferences.status;

      if (this.status === CANDIDATE_STATUSES.VALIDATE) {
        this.$store.commit("auth/setHomepage");
        this.$router.push("/writer");
      }

      this.$forceUpdate();
    },
  },
  created() {
    this.getData();
  },
  computed: {
    ...mapGetters("auth", ["writer", "candidate"]),

    steps: {
      get() {
        return this.$store.state.wizard.steps;
      },
      set(value) {
        this.$store.commit("wizard/setSteps", value);
      },
    },

    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },

    company: {
      get() {
        return this.$store.state.company.company;
      },
      set(value) {
        this.$store.commit("company/setCompany", value);
      },
    },

    baseSteps() {
      return [
        {
          index: 0,
          form_steps_type: 1,
          label: this.$tp("user.details.titles.personal_information"),
          title: this.$t("user.details.titles.your_personal_information"),
          description: this.$t("forms.candidate.welcome"),
          duration: "1 " + this.$t("common.words.min"),
          step_count: 1,
          content: [
            {
              index: 0,
              name: "PersonnalInformation",
              required: true,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: false,
          isStepValid: false,
        },
        {
          index: 1,
          form_steps_type: 2,
          label: this.$t("user.details.titles.billing_details"),
          title: this.$t("forms.candidate.billing_contact"),
          description: this.$t("forms.candidate.eu_writer_requirement"),
          duration: "1 " + this.$t("common.words.min"),
          step_count: 1,
          content: [
            {
              index: 0,
              name: "InvoicesInformation",
              required: true,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: this.$t("forms.candidate.formation.formation"),
          title: this.$t("forms.candidate.experience"),
          description: this.$t("forms.candidate.curious_about_experience"),
          duration: "3 " + this.$t("common.words.min"),
          step_count: 3,
          content: [
            {
              index: 0,
              name: "FormationExperience",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: this.$t("forms.candidate.formation.formation"),
          title: this.$t("forms.candidate.formation.formation"),
          description: this.$t("forms.candidate.training"),
          duration: "3 " + this.$t("common.words.min"),
          step_count: 3,
          content: [
            {
              index: 0,
              name: "FormationInformation",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: this.$t("forms.candidate.formation.formation"),
          title: "SEO",
          description: this.$t("forms.candidate.seo_assessment"),
          duration: "3 " + this.$t("common.words.min"),
          step_count: 3,
          content: [
            {
              index: 0,
              name: "FormationSEO",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: this.$t("forms.candidate.writing"),
          title: this.$t("forms.candidate.writing"),
          description: this.$t("forms.candidate.professional_writing_info"),
          duration: "2 " + this.$t("common.words.min"),
          step_count: 2,
          content: [
            {
              index: 0,
              name: "FormationRedactionWork",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: this.$t("forms.candidate.writing"),
          title: this.$t("forms.candidate.monthly_word_count"),
          description: this.$t("forms.candidate.max_monthly_word_count"),
          duration: "2 " + this.$t("common.words.min"),
          step_count: 2,
          content: [
            {
              index: 0,
              name: "FormationRedactionQuantityWord",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: this.$t("forms.candidate.writer_profile"),
          title: this.$t("forms.candidate.your_profile"),
          description: this.$t("forms.candidate.profile_visibility_info"),
          duration: "4 " + this.$t("common.words.min"),
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilInformation",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: this.$t("forms.candidate.profile"),
          title: this.$t("forms.candidate.favorite_topics"),
          description: this.$t("forms.candidate.choose_mastered_text_types"),
          duration: "4 " + this.$t("common.words.min"),
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilThematic",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 3,
          form_steps_type: 2,
          label: this.$t("forms.candidate.profile"),
          title: this.$t("forms.candidate.favorite_text_types"),
          description: this.$t("forms.candidate.choose_mastered_text_types"),
          duration: "4 " + this.$t("common.words.min"),
          step_count: 6,
          content: [
            {
              index: 0,
              name: "TypeTexts",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 4,
          form_steps_type: 2,
          label: this.$t("forms.candidate.profile"),
          title: this.$t("forms.candidate.offered_options"),
          description: this.$t("forms.candidate.options_competence_warning"),
          duration: "1 " + this.$t("common.words.min"),
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilOption",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 5,
          form_steps_type: 2,
          label: this.$t("forms.candidate.profile"),
          title: this.$t("forms.candidate.languages_written"),
          description: this.$t("forms.candidate.native_writer_requirement"),
          duration: "1 " + this.$t("common.words.min"),
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilLanguage",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 6,
          form_steps_type: 2,
          label: this.$t("forms.candidate.profile"),
          title: this.$t("forms.candidate.sample_texts"),
          description: this.$t("forms.candidate.upload_sample_texts"),
          duration: "1 " + this.$t("common.words.min"),
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilTextExample",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
      ];
    },

    fullSteps() {
      return [
        {
          index: 0,
          form_steps_type: 1,
          label: this.$tp("user.details.titles.personal_information"),
          title: this.$t("user.details.titles.your_personal_information"),
          description: this.$t("forms.candidate.welcome"),
          duration: "1 " + this.$t("common.words.min"),
          step_count: 1,
          content: [
            {
              index: 0,
              name: "PersonnalInformation",
              required: true,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: false,
          isStepValid: false,
        },
        {
          index: 1,
          form_steps_type: 2,
          label: this.$t("user.details.titles.billing_details"),
          title: this.$t("forms.candidate.billing_contact"),
          description: this.$t("forms.candidate.eu_writer_requirement"),
          duration: "1 " + this.$t("common.words.min"),
          step_count: 1,
          content: [
            {
              index: 0,
              name: "InvoicesInformation",
              required: true,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 2,
          form_steps_type: 2,
          label: this.$t("forms.candidate.formation.formation"),
          title: this.$t("forms.candidate.experience"),
          description: this.$t("forms.candidate.curious_about_experience"),
          duration: "3 " + this.$t("common.words.min"),
          step_count: 3,
          content: [
            {
              index: 0,
              name: "FormationExperience",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 3,
          form_steps_type: 2,
          label: this.$t("forms.candidate.formation.formation"),
          title: this.$t("forms.candidate.formation.formation"),
          description: this.$t("forms.candidate.training"),
          duration: "3 " + this.$t("common.words.min"),
          step_count: 3,
          content: [
            {
              index: 0,
              name: "FormationInformation",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 4,
          form_steps_type: 2,
          label: this.$t("forms.candidate.formation.formation"),
          title: "SEO",
          description: this.$t("forms.candidate.seo_assessment"),
          duration: "3 " + this.$t("common.words.min"),
          step_count: 3,
          content: [
            {
              index: 0,
              name: "FormationSEO",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 5,
          form_steps_type: 2,
          label: this.$t("forms.candidate.writing"),
          title: this.$t("forms.candidate.writing"),
          description: this.$t("forms.candidate.professional_writing_info"),
          duration: "2 " + this.$t("common.words.min"),
          step_count: 2,
          content: [
            {
              index: 0,
              name: "FormationRedactionWork",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 6,
          form_steps_type: 2,
          label: this.$t("forms.candidate.writing"),
          title: this.$t("forms.candidate.monthly_word_count"),
          description: this.$t("forms.candidate.max_monthly_word_count"),
          duration: "2 " + this.$t("common.words.min"),
          step_count: 2,
          content: [
            {
              index: 0,
              name: "FormationRedactionQuantityWord",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 7,
          form_steps_type: 2,
          label: this.$t("forms.candidate.writer_profile"),
          title: this.$t("forms.candidate.your_profile"),
          description: this.$t("forms.candidate.profile_visibility_info"),
          duration: "4 " + this.$t("common.words.min"),
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilInformation",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 8,
          form_steps_type: 2,
          label: this.$t("forms.candidate.profile"),
          title: this.$t("forms.candidate.favorite_topics"),
          description: "Choisis uniquement tes spécialités.",
          duration: "4 " + this.$t("common.words.min"),
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilThematic",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 9,
          form_steps_type: 2,
          label: this.$t("forms.candidate.profile"),
          title: this.$t("forms.candidate.favorite_text_types"),
          description: this.$t("forms.candidate.choose_mastered_text_types"),
          duration: "4 " + this.$t("common.words.min"),
          step_count: 6,
          content: [
            {
              index: 0,
              name: "TypeTexts",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 10,
          form_steps_type: 2,
          label: this.$t("forms.candidate.profile"),
          title: this.$t("forms.candidate.offered_options"),
          description: this.$t("forms.candidate.options_competence_warning"),
          duration: "1 " + this.$t("common.words.min"),
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilOption",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 11,
          form_steps_type: 2,
          label: this.$t("forms.candidate.profile"),
          title: this.$t("forms.candidate.languages_written"),
          description: this.$t("forms.candidate.native_writer_requirement"),
          duration: "1 " + this.$t("common.words.min"),
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilLanguage",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 12,
          form_steps_type: 2,
          label: this.$t("forms.candidate.profile"),
          title: this.$t("forms.candidate.sample_texts"),
          description: this.$t("forms.candidate.upload_sample_texts"),
          duration: "1 " + this.$t("common.words.min"),
          step_count: 6,
          content: [
            {
              index: 0,
              name: "ProfilTextExample",
              required: true,
              value: null,
            },
          ],
          visible: true,
          isStepVisible: false,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 13,
          form_steps_type: 1,
          label: this.$t("forms.candidate.contract_signature"),
          title: this.$t("forms.candidate.service_contract"),

          duration: "3 " + this.$t("common.words.min"),
          step_count: 1,
          content: [
            {
              index: 0,
              name: "Contract",
              required: true,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
        {
          index: 14,
          form_steps_type: 1,
          label: this.$t("forms.candidate.account_creation"),
          title: this.$t("forms.candidate.account_ready"),
          duration: "1 " + this.$t("common.words.min"),
          step_count: 1,
          content: [
            {
              index: 0,
              name: "CreateWriterAccount",
              required: true,
            },
          ],
          visible: true,
          isStepVisible: true,
          isStepDisabled: true,
          isStepValid: false,
        },
      ];
    },
  },
};
</script>
<style>
.mainFormView nav {
  display: none;
}
.navButton p {
  font-family: Athletics !important;
}
.is-inline {
  display: none;
}
</style>
