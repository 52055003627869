<template>
  <div style="margin-bottom: 0.5rem">
    <div v-if="field.isEditableFormat && !isEditing">
      <OrderBriefArticle
        :isEditing="isEditing"
        :field="field"
        :value="
          field.path && field.value
            ? $t(`${field.path}.${field.value}`)
            : field.value
        "
        @start-editing="isEditing = true"
      />
      <CardValidationField
        :field="field.isValid"
        :is-next-step="isNextStep"
        :message="$t('wizard.message.required')"
      />
    </div>
    <div
      v-if="!field.isEditableFormat || (field.isEditableFormat && isEditing)"
    >
      <div class="isSmallField mt-2" v-if="isSmallField">
        <b-field :label="field.label ? field.label : ''">
          <b-select v-model="selected" class="is-fullwidth">
            <option
              v-for="option in options"
              :key="option.value"
              :value="option.value"
            >
              {{ option.text }}
            </option>
          </b-select>

          <PartialSaveFormValueButton
            v-if="field.isEditable && field.isUserView"
            :field="field"
            :before="persistValue"
            :callback="stopEditing"
          />
        </b-field>
        <CardValidationField
          :field="field.isValid"
          :is-next-step="isNextStep"
          :message="$t('wizard.message.required')"
        />
      </div>
      <div v-else>
        <div class="columns is-multiline mt-2">
          <b-field
            class="column is-4-tablet is-4-desktop is-4-widescreen px-2 py-1"
          >
            <b-select v-model="selected" type="is-pink-light">
              <option
                v-for="option in options"
                :value="option.value"
                :key="option.value"
              >
                {{ option.text }}
              </option>
            </b-select>
            <br />
            <p style="padding-left: 0.5rem; padding-top: 0.5rem" v-if="unity">
              {{ unity }}
            </p>
          </b-field>
        </div>
        <CardValidationField
          :field="field.isValid"
          :is-next-step="isNextStep"
          :message="$t('wizard.message.required')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardValidationField from "@/components/UI/card/CardValidationField";
import OrderBriefArticle from "@/components/Model/Order/OrderBriefArticle";

export default {
  name: "SelectItem",
  props: ["field", "indexStep", "indexField", "isSmallField", "isNextStep"],
  components: { CardValidationField, OrderBriefArticle },
  data() {
    return {
      isEditing: false,
      selected: this.field.value ? `${this.field.value}` : null,
      options: this.field.options ? this.field.options : [],
      maxValue: this.field.maxValue ? `${this.field.maxValue}` : 100,
      unity: this.field.unity ? `${this.field.unity}` : null,
      type: this.field.type ? `${this.field.type}` : "",
    };
  },

  methods: {
    stopEditing() {
      this.isEditing = false;
    },

    saveComponent() {
      if (this.field.isUserView) {
        this.persistValue();
      }
    },

    persistValue() {
      this.$emit("save-result", {
        index: this.indexStep,
        index_field: this.indexField,
        value_name: this.field.value_name,
        model: this.field.model,
        value: this.selected ? this.selected : null,
      });
    },

    createOption() {
      if (this.type == "number") {
        this.options = [];

        for (let number = 1; number <= this.maxValue; number++) {
          this.options.push({ value: number, text: number });
        }
      } else if (this.type == "withTranslation") {
        const keys = Object.keys(this.$t(this.field.path));
        keys.forEach((key) => {
          this.options.push({
            value: key,
            text: this.$t(`${this.field.path}.${key}`),
          });
        });
      }
    },
  },

  mounted() {
    this.createOption();
  },

  watch: {
    selected: function (newValue) {
      if (!this.field.isUserView) {
        this.$emit("save-result", {
          index: this.indexStep,
          index_field: this.indexField,
          value_name: this.field.value_name,
          model: this.field.model,
          value: newValue,
        });
      }
    },
  },
};
</script>
