<template>
  <main class="section">
    <b-loading is-full-page :active.sync="dataFetching" />
    <div v-if="componentReady">
      <div
        class="is-flex is-flex-wrap-wrap is-justify-content-space-between is-align-items-center"
      >
        <div>
          <h2>{{ $t("website.titles.my") }}</h2>
          <router-link
            v-if="dashboardView"
            class="has-text-blue is-size-6 is-underlined"
            :to="{ path: `${homepage}/websites` }"
          >
            {{ $t("website.collection.actions.view_all_websites") }}
          </router-link>
        </div>
        <ButtonRouterLink
          size="large"
          :link="`${homepage}/new-website`"
          :text="$t('website.actions.new')"
          icon="IconPlus"
          color="pink"
          class="is-fullheight"
        />
      </div>
      <form class="is-flex is-flex-wrap-wrap mt-2">
        <b-field label="">
          <b-autocomplete
            v-model="websiteSearch"
            :data="websites"
            field="domain"
            clearable
            icon="fas fa-search"
            :placeholder="$t('website.filters.search_by_domain')"
            @select="(option) => (selected = option)"
          >
            <template #empty>{{ $t("website.filters.no_result") }}</template>
          </b-autocomplete>
        </b-field>
      </form>
      <div
        class="box has-background-white mt-2"
        v-for="site in websites"
        :key="site.id"
      >
        <router-link
          :to="{ path: `${homepage}/website/${site.id}` }"
          class="is-flex is-align-items-center"
        >
          <icon-ribbon icon="IconGlobe" />
          <div>
            <h5 v-if="site.name">{{ site.name }}</h5>
            <h5 v-else>{{ site.domain }}</h5>
            <p v-if="site.thematic" class="body1">{{ site.thematic }}</p>
            <p
              v-if="dashboardView"
              class="has-text-blue is-size-7 is-underlined"
            >
              {{ $t("website.collection.actions.view_all_orders") }}
            </p>
          </div>
        </router-link>

        <div class="mt-6">
          <div
            v-if="
              site.orders.length === 0 ||
              (site.orders.length === 1 &&
                !site.approved_orders_count &&
                parseInt(site.orders[0].status) <= 2)
            "
            class="columns is-multiline"
          >
            <div class="column is-4-tablet is-3-desktop is-3-widescreen">
              <ButtonIcon
                v-if="site.orders.length === 0 && site.approved_orders_count"
                color="pink"
                size="large"
                icon="IconPlus"
                class="mr-2"
                :text="$t('order.actions.new')"
                @click-button="activateNewOrderConfirmationModal(site)"
              />
              <b-button
                v-else
                tag="router-link"
                :to="
                  site.orders[0]
                    ? `${homepage}/new-website/${site.id}/order/${site.orders[0].reference}`
                    : `${homepage}/new-website/${site.id}`
                "
                type="is-pink"
                inverted
                class="button is-large is-fullwidth has-border-pink has-hover-bottom"
              >
                {{ $t("website.actions.resume_order") }}
              </b-button>
            </div>
          </div>

          <div class="columns is-multiline" v-else>
            <article
              v-for="order in site.orders"
              :key="order.id"
              class="column is-4-tablet is-3-desktop is-3-widescreen has-background-white"
            >
              <OrderCard :order="order" />
            </article>

            <div
              v-if="shouldDisplayNewOrderButton(site)"
              class="column is-4-tablet is-3-desktop is-3-widescreen"
            >
              <ButtonIcon
                color="pink"
                size="large"
                icon="IconPlus"
                class="width-100"
                :text="$t('order.actions.new')"
                @click-button="activateNewOrderConfirmationModal(site)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <NewOrderConfirmationModal
      v-if="selectedSite"
      v-model="isNewOrderConfirmationModalActive"
      :website="selectedSite"
    />
  </main>
</template>
<script>
import { mapActions, mapState } from "vuex";
import ButtonIcon from "@/components/UI/buttons/ButtonIcon";
import ButtonRouterLink from "@/components/UI/buttons/ButtonRouterLink";
import IconRibbon from "@/components/UI/icons/IconRibbon";
import OrderCard from "@/components/Model/Order/OrderCard";
import NewOrderConfirmationModal from "@/components/Model/Order/NewOrderConfirmationModal";

export default {
  title: "metadata.title.site.list",
  name: "MySitesView",
  components: {
    ButtonRouterLink,
    ButtonIcon,
    IconRibbon,
    OrderCard,
    NewOrderConfirmationModal,
  },
  props: {
    dashboardView: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      componentReady: false,
      dataFetching: false,
      websiteSearch: "",
      selected: null,
      selectedSite: null,
      isNewOrderConfirmationModalActive: false,
      filteredDataArray: [],
    };
  },
  computed: {
    ...mapState("auth", ["homepage"]),
    ...mapState("website", ["websites"]),
    websitesConfig: {
      get() {
        return this.$store.state.website.websitesConfig;
      },
      set(value) {
        this.$store.commit("website/setWebsitesConfig", value);
      },
    },
  },
  watch: {
    websiteSearch: function (newValue) {
      if (newValue) {
        this.websitesConfig.filters = { domain: newValue };
        this.getWebSitesHandler();
      } else {
        this.websitesConfig.filters = null;
        this.getWebSitesHandler();
      }
    },
  },
  methods: {
    ...mapActions("website", ["getWebsitesDashboard"]),

    getWebSitesHandler() {
      this.dataFetching = true;
      this.websitesConfig.filters = {
        ...(this.dashboardView ? { has_in_progress_order: true } : {}),
        ...(this.websitesConfig.filters || {}),
      };

      this.getWebsitesDashboard()
        .then(() => {
          this.componentReady = true;
          this.dataFetching = false;
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        });
    },

    shouldDisplayNewOrderButton(site) {
      return site.has_brief || site.approved_orders_count;
    },

    activateNewOrderConfirmationModal(site) {
      this.selectedSite = site;
      this.isNewOrderConfirmationModalActive = true;
    },
  },
  mounted() {
    this.websiteSearch = null;
  },
};
</script>
