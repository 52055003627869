<template>
  <div v-if="option.id">
    <b-collapse
      class="card"
      animation="slide"
      :aria-id="`typeParameter${option.id}`"
      :open="false"
    >
      <template #trigger="props">
        <div
          class="card-header"
          role="button"
          :aria-controls="`typeParameter${option.id}`"
          :aria-expanded="props.open"
        >
          <p class="card-header-title">
            {{ $t("option.children.type_parameter.title") }}
          </p>
          <a class="card-header-icon">
            <component
              :is="props.open ? 'iconChevronUp' : 'IconChevronDown'"
              class="icon-size-1_5"
              style="fill: #202560"
            />
          </a>
        </div>
      </template>

      <b-table
        :data="filteredOrderTypes"
        id="order-type-option-parameter-table"
        narrowed
      >
        <b-table-column
          field="order_type"
          :label="$t('option.fields.order_type')"
          v-slot="props"
        >
          {{ $t(`text.variants.type.${props.row.order_type}`) }}
        </b-table-column>

        <b-table-column
          field="is_visible"
          :label="$t('option.children.type_parameter.fields.is_visible')"
          v-slot="props"
          centered
        >
          <b-checkbox
            type="is-primary"
            v-model="option.type_parameters[props.row.order_type].is_visible"
          />
        </b-table-column>

        <b-table-column
          field="is_required"
          :label="$ts('common.words.required')"
          v-slot="props"
          centered
        >
          <b-checkbox
            type="is-primary"
            v-model="option.type_parameters[props.row.order_type].is_required"
          />
        </b-table-column>

        <b-table-column
          field="is_recommended"
          :label="$t('option.children.type_parameter.fields.is_recommended')"
          v-slot="props"
          centered
        >
          <b-checkbox
            type="is-primary"
            v-model="
              option.type_parameters[props.row.order_type].is_recommended
            "
          />
        </b-table-column>
      </b-table>
    </b-collapse>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("order-type", ["orderTypes"]),

    option: {
      get() {
        return this.$store.state.option.option;
      },
      set(value) {
        this.$store.commit("option/setOption", value);
      },
    },

    filteredOrderTypes() {
      return this.orderTypes.filter(
        (orderType) => ![6].includes(orderType.order_type)
      );
    },
  },
};
</script>

<style scoped>
::v-deep .control-label {
  display: none;
}
</style>
