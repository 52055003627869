<template>
  <div>
    <DashboardHeader />

    <b-select
      v-model="activeTab"
      class="mx-8 mt-4 is-visible-medium-small-none"
    >
      <option
        v-for="filteredTab in filteredTabs"
        :value="filteredTab.orderKey"
        :key="filteredTab.orderKey"
      >
        {{ filteredTab.label }} : {{ orderCounts[filteredTab.orderKey] }}
      </option>
    </b-select>

    <b-tabs v-model="activeTab" class="px-6 userTab tabs-nav-hidden-mobile">
      <template v-for="tab in tabs">
        <b-tab-item
          v-if="tab.displayIfHasValue ? orderCounts[tab.orderKey] : true"
          :key="tab.orderKey"
          :label="`${tab.label} : ${orderCounts[tab.orderKey]}`"
          :value="tab.orderKey"
        >
          <OrdersTab
            v-if="tab.orderKey == activeTab || tab.opened"
            :use-local-state="tab.useLocalState"
            class="pt-4 p-2"
            :default-include="['thematic']"
            :default-filter="defaultFilters.writer[tab.orderKey] || {}"
            :default-sort="{
              field: 'desired_delivery_date',
              direction: 'asc',
            }"
            :default-page="defaultPageOfDefaultActiveTab(tab.orderKey)"
            :default-per-page="defaultPerPageOfDefaultActiveTab(tab.orderKey)"
          />
        </b-tab-item>
      </template>
    </b-tabs>
  </div>
</template>

<script>
import DashboardHeader from "@/components/UI/DashboardHeader";
import OrdersTab from "@/components/Model/Order/OrdersTab";
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  name: "DashboardWriter",
  components: {
    DashboardHeader,
    OrdersTab,
  },

  data() {
    const activeTab = this.$route.query.tab || "in_progress_orders";

    return {
      defaultActiveTab: this.$route.query.tab,
      activeTab,
      tabs: [
        {
          label: this.$t("dashboard.writer.navs.in_progress_orders"),
          orderKey: "in_progress_orders",
          useLocalState: true,
          opened: false,
        },

        {
          label: this.$t("dashboard.writer.navs.urgent_orders"),
          orderKey: "urgent_orders",
          useLocalState: true,
          opened: false,
        },

        {
          label: this.$t("dashboard.writer.navs.new_orders"),
          orderKey: "new_orders",
          useLocalState: true,
          opened: false,
        },

        {
          label: this.$t("dashboard.writer.navs.all_orders"),
          orderKey: "all_orders",
          useLocalState: false,
          opened: false,
        },
        {
          label: this.$t("dashboard.writer.navs.orders_has_subscription"),
          orderKey: "orders_has_subscription",
          useLocalState: true,
          opened: false,
          displayIfHasValue: true,
        },
      ],
    };
  },

  mounted() {
    if (this.user.is_active === false) {
      this.tabs.splice(2, 1);
    }

    this.retrieveOrdersCountsFromLocalStorage();

    this.tabs.forEach((tab) => {
      this.getOrdersCount({
        key: tab.orderKey,
        filters: this.defaultFilters.writer[tab.orderKey] || {},
      });
    });

    this.setActiveTabAsOpened();
  },

  computed: {
    ...mapState("auth", ["user"]),
    ...mapState("order", ["orderCounts", "defaultFilters"]),
    filteredTabs() {
      return this.tabs.filter(
        (tab) => !tab.displayIfHasValue || this.orderCounts[tab.orderKey] > 0
      );
    },
  },

  methods: {
    ...mapActions("order", ["getOrdersCount"]),
    ...mapMutations("order", ["retrieveOrdersCountsFromLocalStorage"]),

    defaultPageOfDefaultActiveTab(tabName) {
      return +(this.defaultActiveTab == tabName
        ? this.$route.query.page || 1
        : 1);
    },

    defaultPerPageOfDefaultActiveTab(tabName) {
      return +(this.defaultActiveTab == tabName
        ? this.$route.query.per_page || 20
        : 20);
    },

    setActiveTabAsOpened() {
      this.tabs.find((tab) => tab.orderKey == this.activeTab).opened = true;
    },

    isActiveTab(tabName) {
      return tabName === this.activeTab;
    },
  },

  watch: {
    activeTab(val) {
      if (val === this.$route.query.tab) return;

      this.$router.replace({
        query: { tab: val },
      });

      this.setActiveTabAsOpened();
    },
  },
};
</script>
