<template>
  <form class="has-background-white mb-5 p-5">
    <b-loading :active.sync="dataSaving" />
    <div>
      <div class="mb-4">
        <span class="has-text-pink">*</span>
        <i>{{ $tp("error.validation.field_required") }}</i>
      </div>

      <div
        v-for="(element, index) in candidateFormFields"
        class="my-2"
        :key="index"
        style="height: 100%"
      >
        <div v-if="element.disabled !== true">
          <span class="is-flex">
            <p class="has-text-blue bold" v-if="element.title">
              {{ element.title }}
            </p>
            <span
              v-if="element.required || element.dynamicRequired()"
              class="iconSize ml-2"
            >
              <i class="fas fa-asterisk"></i>
            </span>
          </span>
          <component
            :key="index"
            :indexStep="index"
            :indexField="index"
            :is="element.name"
            :field="element"
            :isSmallField="element.small"
            :is-next-step="formValidated"
            @save-result="saveResult"
            class="is-flex is-flex-direction-column is-justify-content-space-evenly mt-1"
          />
        </div>
      </div>
    </div>

    <div
      class="is-flex is-justify-content-space-between is-align-items-center mt-4"
    >
      <router-link
        to="/login"
        class="btn has-text-blue is-outlined has-background-white has-border-none is-underlined"
        :disabled="dataSaving"
      >
        {{ $t("forms.candidate.have_account") }}
      </router-link>
      <b-button
        inverted
        type="is-pink"
        class="button is-large has-border-pink has-hover-bottom"
        :disabled="dataSaving"
        @click.prevent="userRegistrationHandler"
      >
        {{ $t("auth.register.actions.register") }}
      </b-button>
    </div>
  </form>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { validateFormInputs } from "@/utils/validation";
import renderMixin from "@/mixins/renderMixin";
import AddressAutocomplete from "@/components/Model/Form/FormItem/AddressAutocomplete";
import BriefText from "@/components/Model/Form/FormItem/BriefText";
import SelectItem from "@/components/Model/Form/FormItem/SelectItem";
import PhoneInput from "@/components/Model/Form/FormItem/PhoneInput";

export default {
  name: "CandidateRegistrationForm",
  mixins: [renderMixin],
  data() {
    return {
      dataSaving: false,
      formValidated: false,
      candidateFormFields: [],
    };
  },
  components: {
    AddressAutocomplete,
    BriefText,
    PhoneInput,
    SelectItem,
  },
  beforeMount() {
    this.candidateFormFields = this.writerFields;
  },
  computed: {
    ...mapGetters("auth", ["homepage"]),
    newCandidate: {
      get() {
        return this.$store.state.guest.newCandidate;
      },
      set(value) {
        this.$store.commit("guest/setNewClient", value);
      },
    },
    writerFields() {
      return [
        {
          title: this.$ts("forms.personal.fields.first_name"),
          name: "BriefText",
          value_name: "firstname",
          required: true,
          value: "",
        },
        {
          title: this.$ts("forms.personal.fields.last_name"),
          name: "BriefText",
          value_name: "lastname",
          required: true,
          value: "",
        },
        {
          title: this.$ts("forms.personal.fields.language"),
          name: "SelectItem",
          value_name: "locale",
          required: true,
          small: true,
          path: "locale",
          type: "withTranslation",
          value: "fr",
        },
        {
          title: this.$ts("forms.personal.fields.phone"),
          name: "PhoneInput",
          value_name: "phone",
          required: true,
          hasErrorMessage: this.$t("error.validation.phone_valid"),
          value: "",
          countryCode: "FR",
        },
        {
          title: this.$ts("forms.personal.fields.email"),
          name: "BriefText",
          value_name: "email",
          required: true,
          value: "",
          regex: "^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$",
          hasErrorMessage: this.$t("error.validation.email_valid"),
        },
        {
          title: this.$t("user.details.titles.password"),
          name: "BriefText",
          type: "password",
          value_name: "password",
          required: true,
          value: "",
          regex: "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})",
          errorMessages: {
            regex: this.$t("error.validation.password_valid"),
          },
        },
        {
          title: this.$t("forms.personal.fields.confirm_password"),
          name: "BriefText",
          type: "password",
          value_name: "password_confirmation",
          required: true,
          value: "",
          customValidation(value, fields) {
            const isValid =
              value ===
              fields.find((field) => field.value_name === "password").value;

            return {
              isValid,
              errorMessage: isValid
                ? null
                : this.$t("error.validation.confirm_password_valid"),
            };
          },
        },
      ];
    },
  },
  methods: {
    ...mapActions("guest", ["registerCandidate"]),

    saveResult(result) {
      this.newCandidate[result.value_name] = result.value;

      if (result.value_name === "country_code") {
        this.candidateFormFields[result.index_field].countryCode = result.value;
      } else {
        this.candidateFormFields[result.index_field].value = result.value;
      }
    },

    validateStep() {
      const validationResults = validateFormInputs(this.candidateFormFields);

      if (!validationResults.isValid) {
        this.candidateFormFields = validationResults.fields;
        this.formValidated = true;
        this.forceRerender();

        return false;
      }

      return true;
    },

    userRegistrationHandler() {
      if (!this.validateStep()) {
        return;
      }

      this.formValidated = false;
      this.dataSaving = true;

      this.registerCandidate(this.newCandidate)
        .then(() => {
          window.location = this.homepage;
        })
        .catch(() => {
          this.dataSaving = false;
        });
    },
  },
};
</script>
<style scoped>
.iconSize {
  font-size: 0.5em;
}
</style>
