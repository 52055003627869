import i18n from "@/i18n/i18n";
export const validateFormInputs = (fields) => {
  fields.forEach((field) => {
    if (field.customValidation && field.value) {
      const customValidationResult = field.customValidation(
        field.value,
        fields
      );
      field.isValid = customValidationResult.isValid;

      if (customValidationResult.errorMessage?.length) {
        field.hasErrorMessage = customValidationResult.errorMessage;
      }

      return;
    }

    const dynamicRequiredIsValid = field.dynamicRequired
      ? field.dynamicRequired()
      : false;

    if (field.value?.length && field.regex && !field.value.match(field.regex)) {
      field.isValid = false;
      field.hasErrorMessage =
        field.errorMessages?.regex || i18n.t("error.validation.invalid_format");
      return;
    }

    if (field.required === true || dynamicRequiredIsValid) {
      if (
        Array.isArray(field.value) &&
        (field.value.every((elem) => elem === null) ||
          field.value.length === 0 ||
          field.hasError)
      ) {
        field.isValid = false;
      } else if (
        field.value & (typeof field.value === "object") &&
        Object.keys(field.value)?.length === 0
      ) {
        field.isValid = false;
      } else if (!field.value || field.value === " " || field.hasError) {
        field.isValid = false;
      } else {
        field.isValid = true;
        field.hasErrorMessage = null;
      }

      if (field.isValid === false) {
        field.hasErrorMessage = i18n.t("error.validation.required");
      }
    } else {
      if (field.hasError && field.dynamicRequired === undefined) {
        field.isValid = false;
      } else {
        field.isValid = true;
        field.hasErrorMessage = null;
      }
    }
  });

  const isValid = fields.filter((field) => field.isValid === false).length;

  return {
    isValid: isValid === 0,
    fields,
  };
};

export const validateFormStepContent = (contentItems) => {
  const validationResultItems = contentItems.map((formItem) => {
    formItem.isValid = validateFormStep(formItem);
    return {
      name: formItem.name?.name,
      value: formItem.value,
      isValid: formItem.isValid,
    };
  });

  return validationResultItems.every((item) => item.isValid === true);
};

const validateFormStep = (formItem) => {
  if (formItem.required !== true) {
    return !formItem.hasError;
  }

  if (formItem.value_type && (formItem.value || formItem.value === 0)) {
    return validateOrderNumberField(formItem);
  } else if (formItem.value_type === null && formItem.value) {
    return false;
  } else {
    return validateFormStepValue(formItem);
  }
};

const validateOrderNumberField = (formItem) => {
  const orderNumberFields = ["OrderWordsNumber", "OrderTextsNumber"];
  return (
    !orderNumberFields.includes(formItem.name) || parseInt(formItem.value) > 0
  );
};

const validateFormStepValue = (formItem) => {
  if (formItem.hasError) {
    return false;
  }

  if (Array.isArray(formItem.value) && isArrayInvalid(formItem.value)) {
    return false;
  }

  if (
    formItem.value &&
    typeof formItem.value === "object" &&
    isObjectInvalid(formItem)
  ) {
    return false;
  }

  if (!formItem.value && formItem.value !== " " && formItem.value !== false) {
    return false;
  }

  return true;
};

const isArrayInvalid = (arrayValue) => {
  return arrayValue.every((elem) => elem === null) || arrayValue.length === 0;
};

const isObjectInvalid = (formItem) => {
  if (Object.prototype.toString.call(formItem.value) === "[object Date]") {
    return false;
  }

  if (
    formItem.name === "BriefCompetitors" &&
    formItem.value.filter((el) => el.url === "").length ===
      formItem.value.length
  ) {
    return true;
  }

  return Object.keys(formItem.value).length === 0;
};
