<template>
  <section class="section is-fullheight">
    <div class="container is-fullheight">
      <div class="columns is-centered is-align-items-center is-fullheight">
        <div class="column is-6-tablet is-5-desktop is-4-widescreen">
          <form class="box has-background-white">
            <h5 v-if="!isForgotPwd" class="mb-2 has-text-centered">
              {{ $t("auth.login.title") }}
            </h5>
            <h5 v-else class="mb-2 has-text-centered">
              {{ $t("auth.forgot_pwd.title") }}
            </h5>
            <div class="field">
              <label for="email" class="label">{{
                $t("auth.login.mail")
              }}</label>
              <input
                id="email"
                v-model="email"
                autocomplete="email"
                autofocus
                class="input has-background-white"
                name="email"
                :placeholder="$t('auth.login.mail')"
                required
                type="email"
              />
            </div>

            <p
              v-if="
                (isForgotPasswordSubmit && mailMessage) ||
                (isSubmit && mailMessage)
              "
              class="body2 has-text-danger mb-2"
            >
              {{ mailMessage }}
            </p>
            <div class="field" v-if="!isForgotPwd">
              <label for="password" class="label">{{
                $t("auth.login.password")
              }}</label>
              <input
                id="password"
                v-model="password"
                autocomplete="current-password"
                class="input has-background-white"
                name="password"
                :placeholder="$t('auth.login.password')"
                required
                type="password"
              />
            </div>
            <p
              v-if="
                (isForgotPasswordSubmit && passwordMessage) ||
                (isSubmit && passwordMessage)
              "
              class="body2 has-text-danger mb-2"
            >
              {{ passwordMessage }}
            </p>
            <div v-if="!isForgotPwd">
              <div class="is-flex is-justify-content-space-between">
                <input
                  type="button"
                  :value="$t('auth.common.actions.forgot_password')"
                  class="btn has-text-blue is-outlined has-background-white has-border-none"
                  @click.prevent="isForgotPwd = true"
                />
                <button
                  v-show="!loading"
                  class="button is-blue"
                  @click.prevent="connection"
                >
                  {{ $t("auth.common.actions.login") }}
                </button>
                <button
                  v-show="loading"
                  class="btn btn-primary btn-auth-loading btn-block waves-effect waves-themed"
                  disabled="disabled"
                  type="button"
                >
                  <span
                    aria-hidden="true"
                    class="spinner-border spinner-border-sm"
                    role="status"
                  ></span>
                  {{ $t("auth.common.actions.login") }}...
                </button>
              </div>
              <div
                class="is-divider is-pink"
                :data-content="$t('auth.register.or_register_as')"
              ></div>
              <div class="is-flex is-justify-content-space-between my-2 px-5">
                <router-link
                  to="/inscription/writer"
                  class="btn has-text-blue is-outlined has-background-white has-border-none"
                >
                  {{ $t("auth.register.writer") }}
                </router-link>

                <router-link
                  to="/inscription"
                  class="btn has-text-blue is-outlined has-background-white has-border-none"
                >
                  {{ $t("auth.register.client") }}
                </router-link>
              </div>
            </div>
            <div class="is-flex is-justify-content-space-between" v-else>
              <button
                v-show="!loading"
                class="button is-blue"
                @click.prevent="forgotPasswordHandler"
              >
                {{ $t("common.actions.send") }}
              </button>
              <button
                v-show="loading"
                class="btn btn-primary btn-auth-loading btn-block waves-effect waves-themed"
                disabled="disabled"
                type="button"
              >
                <span
                  aria-hidden="true"
                  class="spinner-border spinner-border-sm"
                  role="status"
                ></span>
                {{ $t("common.actions.send") }}...
              </button>

              <input
                type="button"
                class="btn has-text-blue is-outlined has-background-white has-border-none"
                :value="$t('auth.common.actions.login_with_existing_account')"
                @click.prevent="() => (isForgotPwd = false)"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  title: "metadata.title.auth.login",
  name: "loginView",
  data: function () {
    return {
      email: "",
      password: "",
      isSubmit: false,
      isForgotPasswordSubmit: false,
      loading: false,
      mailregExp:
        /^((\w[^W]+)[.-]?){1,}@(([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3})|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      mailMessage: "",
      passwordMessage: "",
      isForgotPwd: false,
      customRedirectRoute: null,
    };
  },
  computed: {
    ...mapState("auth", ["isConnected", "redirectRouteAfterLogin"]),
    ...mapGetters("auth", ["homepage"]),
  },
  methods: {
    ...mapActions("auth", {
      login: "login",
      forgotPwd: "forgotPassword",
    }),
    connection() {
      this.loading = true;
      this.isSubmit = true;
      this.confirmMail(this.email);
      this.confirmPassword(this.password);

      if (this.mailMessage && this.passwordMessage) {
        this.loading = false;
        return;
      }

      this.login({ email: this.email, password: this.password })
        .then(() => {
          this.loading = false;

          if (this.customRedirectRoute) {
            this.$store.commit("auth/setRedirectRoute", null);
            this.$router.push(this.customRedirectRoute);
            return;
          }

          this.$router.push(this.homepage);
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
          this.loading = false;
        });
    },
    forgotPasswordHandler() {
      this.loading = true;
      this.isForgotPasswordSubmit = true;
      this.confirmMail(this.email);

      if (this.mailMessage) {
        this.loading = false;
        return;
      }

      this.forgotPwd({ email: this.email })
        .then(() => {
          this.loading = false;
          this.$store.dispatch("notify", {
            type: "is-success",
            message: this.$t("auth.forgot_pwd.success_message"),
          });
          this.isForgotPasswordSubmit = false;
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
          this.isForgotPasswordSubmit = false;
          this.loading = false;
        });
    },
    // vérifie la saisie du champ email
    confirmMail(email) {
      if (!email) {
        this.mailMessage = this.$t("error.validation.required");
      } else if (!this.mailregExp.test(email)) {
        this.mailMessage = this.$t("error.validation.mail_valid");
      } else {
        this.mailMessage = "";
      }
    },
    confirmPassword(password) {
      if (!password) {
        this.passwordMessage = this.$t("error.validation.required");
      } else {
        this.passwordMessage = "";
      }
    },
  },
  mounted() {
    if (this.isConnected) {
      return this.$router.push(this.homepage);
    }

    if (this.redirectRouteAfterLogin) {
      this.customRedirectRoute = this.redirectRouteAfterLogin;
    }

    if (this.$route.query.isForgotPwd != undefined) {
      this.isForgotPwd = true;
      this.$router.replace({ query: {} });
    }
  },
};
</script>

<style scoped>
.h2 {
  margin: 0.5em 0 1em 0;
}
a.btn {
  font-size: 13.33px;
}
</style>
