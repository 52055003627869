<template>
  <form
    class="is-flex is-flex-direction-column is-justify-content-space-evenly is-fullheight"
  >
    <b-field
      :label="$ts('forms.personal.fields.first_name') + ' *'"
      class="my-2"
    >
      <b-input v-model="user.firstname" type="text" class="b-input" />
    </b-field>
    <CardValidationField
      v-if="!user.firstname"
      :field="false"
      :is-next-step="isUserSend"
      :message="$t('error.validation.required')"
    />
    <b-field
      :label="$ts('forms.personal.fields.last_name') + ' *'"
      class="my-2"
    >
      <b-input v-model="user.lastname" type="text" class="b-input" />
    </b-field>
    <CardValidationField
      v-if="!user.lastname"
      :field="false"
      :is-next-step="isUserSend"
      :message="$t('error.validation.required')"
    />

    <b-field :label="$ts('forms.company.company') + ' *'" class="my-2">
      <div class="is-flex is-align-items-center">
        <b-autocomplete
          v-model="companySearch"
          :data="companyOptions"
          field="name"
          clearable
          icon="fas fa-search"
          :placeholder="$t('website.filters.search')"
          @select="(option) => (companySelected = option)"
          required
          class="is-fullwidth"
        >
        </b-autocomplete>
        <ButtonIcon
          color="blue"
          size="small"
          icon="IconPlus"
          @click-button="openModalAddCompany"
        />
      </div>
    </b-field>
    <CardValidationField
      v-if="!user.company_id"
      :field="false"
      :is-next-step="isUserSend"
      :message="$t('error.validation.required')"
    />

    <b-field :label="$ts('forms.personal.fields.email') + ' *'" class="my-2">
      <b-input v-model="user.email" type="email" class="b-input" />
    </b-field>

    <CardValidationField
      v-if="mailMessage"
      :field="false"
      :is-next-step="isUserSend"
      :message="mailMessage"
    />

    <div class="columns is-multiline my-2">
      <b-field
        :label="$ts('forms.personal.fields.language')"
        class="column is-3-tablet is-3-desktop is-3-widescreen is-3-fullhd py-0 my-0 mb-2"
      >
        <b-select v-model="user.locale" class="is-fullwidth">
          <option class="is-fullwidth" value="fr">{{ $t("locale.fr") }}</option>
          <option class="is-fullwidth" value="en">{{ $t("locale.en") }}</option>
          <option class="is-fullwidth" value="es">{{ $t("locale.es") }}</option>
        </b-select>
      </b-field>

      <b-field
        :label="$ts('forms.personal.fields.phone')"
        class="column is-9-tablet is-9-desktop is-9-widescreen is-9-fullhd py-0 my-0"
      >
        <PhoneInput
          :title="$ts('forms.personal.fields.phone')"
          :key="user?.id"
          :field="userPhoneObject"
          @save-result="updatePhoneAndCountryCodeValue"
        />
      </b-field>
    </div>
    <b-field :label="$t('user.details.titles.password') + ' *'" class="my-2">
      <b-input v-model="user.password" type="text" class="b-input" />
    </b-field>
    <CardValidationField
      v-if="passwordMessage"
      :field="false"
      :is-next-step="isUserSend"
      :message="passwordMessage"
    />
    <b-field
      :label="$t('forms.personal.fields.confirm_password') + ' *'"
      class="mt-2 mb-1"
    >
      <b-input
        v-model="user.password_confirmation"
        type="text"
        class="b-input"
      />
    </b-field>

    <div class="is-flex is-align-items-center">
      <ButtonIcon
        color="blue"
        :text="$t('common.actions.generate')"
        size="small"
        icon=""
        @click-button="createPassword"
      />
      <p class="body2 has-text-grey-light ml-2" v-if="passwordGenerated">
        {{ $t("admin.user.actions.generate_password.info") }} :
        {{ passwordGenerated }}
      </p>
    </div>
    <b-field :label="$tp('role.role') + ' *'" v-if="admin" class="my-3">
      <div class="columns is-multiline px-1">
        <div
          v-for="option in roles"
          :key="option.id"
          class="column is-4-tablet is-4-desktop is-3-widescreen is-3-fullhd px-1 py-1"
        >
          <input
            v-model="user.roles"
            type="checkbox"
            class="labelForCheck is-grey-lighter"
            :id="`roles-${option}`"
            :value="option"
          />
          <label
            class="b-checkbox checkbox button is-fullwidth text-left"
            :for="`roles-${option}`"
            >{{ option }}
          </label>
        </div>
      </div>
    </b-field>
    <div
      class="is-flex is-justify-content-space-between my-2 is-align-items-center"
    >
      <component
        v-if="
          user.reference &&
          (user.roles.includes('writer') || user.roles.includes('candidate'))
        "
        class="mr-2"
        :is="user.is_active ? 'BtnDeactivateUser' : 'BtnActivateUser'"
        :user="user"
        @user-satus-changed="() => $emit('user-saved')"
      />

      <ButtonIcon
        v-if="admin && user.reference"
        class="has-text-blue"
        icon="IconDelete"
        size="small"
        :loading="saving"
        :disabled="saving"
        @click-button="confirmDelete(user.reference)"
      />

      <ButtonIcon
        color="pink"
        size="large"
        :text="
          isCreateOrder ? $t('website.actions.new') : $t('common.actions.save')
        "
        :icon="isCreateOrder ? 'IconPlus' : 'IconSave'"
        class="mt-2 ml-auto"
        :loading="saving"
        :disabled="saving"
        @click-button="createUserHandler"
      />
    </div>
  </form>
</template>
<script>
import ButtonIcon from "@/components/UI/buttons/ButtonIcon";
import CardValidationField from "@/components/UI/card/CardValidationField";
import PhoneInput from "@/components/Model/Form/FormItem/PhoneInput";
import BtnDeactivateUser from "@/components/Model/User/BtnDeactivateUser";
import BtnActivateUser from "@/components/Model/User/BtnActivateUser";
import { mapActions, mapState, mapGetters } from "vuex";
import { preparePhoneObject } from "@/utils/index";

export default {
  name: "AddUserForm",
  components: {
    ButtonIcon,
    CardValidationField,
    PhoneInput,
    BtnDeactivateUser,
    BtnActivateUser,
  },
  props: ["isCreateOrder"],

  data() {
    return {
      saving: false,
      mailMessage: "",
      passwordMessage: "",
      companySelected: " ",
      companyMessage: "",
    };
  },

  created() {
    if (this.isCreateOrder) {
      this.user = this.newUser;
    }
  },

  watch: {
    companySearch: function (newValue) {
      this.companiesConfig.filters = { name: newValue };
      this.getCompanies()
        .then(() => {
          this.isloading = true;
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        });
    },
  },

  computed: {
    ...mapGetters("auth", ["admin"]),
    ...mapState("company", ["companies"]),
    ...mapState("role", ["roles"]),
    ...mapState("user", ["newUser"]),

    isUserSend: {
      get() {
        return this.$store.state.user.isUserSend;
      },
      set(value) {
        this.$store.commit("user/setIsUserSend", value);
      },
    },

    passwordGenerated: {
      get() {
        return this.$store.state.user.passwordGenerated;
      },
      set(value) {
        this.$store.commit("user/setPasswordGenerated", value);
      },
    },

    user: {
      get() {
        return this.$store.state.user.user;
      },
      set(value) {
        this.$store.commit("user/setUser", value);
      },
    },

    companiesConfig: {
      get() {
        return this.$store.state.company.companiesConfig;
      },
      set(value) {
        this.$store.commit("company/setCompaniesConfig", value);
      },
    },

    companySearch: {
      get() {
        return this.$store.state.company.companySearch;
      },
      set(value) {
        this.$store.commit("company/setCompanySearch", value);
      },
    },

    companyOptions() {
      return this.companies?.data ? this.companies.data : this.companies;
    },

    userPhoneObject() {
      return preparePhoneObject(this.user.phone, this.user.country_code);
    },
  },

  methods: {
    ...mapActions("company", ["getCompanies"]),
    ...mapActions("user", ["createUser", "updateUser", "deleteUser"]),

    createUserHandler() {
      this.saving = true;
      this.isUserSend = true;
      this.validMail(this.user.email);
      this.validPassword(this.user.password, this.user.password_confirmation);

      if (this.companySelected && this.companySelected.id) {
        this.user.company_id = this.companySelected.id;
      }

      if (
        this.mailMessage ||
        this.passwordMessage ||
        !this.user.firstname ||
        !this.user.lastname ||
        !this.user.company_id ||
        this.companyMessage
      ) {
        this.saving = false;
        return;
      }

      let endpoint = this.user.reference
        ? this.updateUser()
        : this.createUser();

      endpoint
        .then((response) => {
          this.isUserSend = false;
          this.$emit("user-saved", response.data.user.reference);
        })
        .catch((e) => {
          this.$store.dispatch("fireError", e);
        })
        .finally(() => {
          this.saving = false;
          this.companySearch = null;
        });
    },

    // vérifie la confirmation du mot de passe (correspondance entre les deux mots de passes saisies)
    validPassword(pwd, confirmpwd) {
      if (!pwd && !this.user.reference) {
        this.passwordMessage = this.$t("error.validation.required");
      } else if (pwd && pwd.length < 8) {
        this.passwordMessage = this.$t("error.validation.min_password_length");
      } else if (pwd && pwd !== confirmpwd) {
        this.passwordMessage = this.$t("error.validation.password_mismatch");
      } else {
        this.passwordMessage = "";
      }
    },
    // vérifie la saisie du champ email
    validMail(email) {
      if (!email) {
        this.mailMessage = this.$t("error.validation.required");
      } else if (!/[a-zA-Z0-9.]*@[a-z]*[.a-z]*/.test(email)) {
        this.mailMessage = this.$t("error.validation.mail_valid");
      } else {
        this.mailMessage = "";
      }
    },

    createPassword() {
      const length = 12;
      const wishlist =
        "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ~!@-#$+abcdefghijklmnopqrstuvwxyz~!@-#$+";

      this.passwordGenerated = Array.from(
        crypto.getRandomValues(new Uint32Array(length))
      )
        .map((x) => wishlist[x % wishlist.length])
        .join("");

      this.updatePassword();
    },

    updatePassword() {
      this.user.password = this.passwordGenerated;
      this.user.password_confirmation = this.passwordGenerated;
    },

    openModalAddCompany() {
      this.$emit("open-modal-add-company");
    },

    updatePhoneAndCountryCodeValue(value) {
      if (["phone", "country_code"].includes(value.value_name)) {
        this.user[value.value_name] = value.value;
      }
    },

    confirmDelete(ref) {
      this.$buefy.dialog.confirm({
        title: this.$t("admin.user.actions.delete.confirm.title"),
        message: this.$t("admin.user.actions.delete.confirm.message"),
        confirmText: this.$t("common.words.yes"),
        cancelText: this.$t("common.actions.cancel"),
        type: "is-danger",
        onConfirm: () => {
          this.deleteUser(ref)
            .finally(() => {
              this.$emit("user-deleted");
            })
            .catch((e) => {
              this.$store.dispatch("fireError", e);
            });
        },
      });
    },
  },
};
</script>
