var render = function render(){var _vm=this,_c=_vm._self._c;return _c('main',{staticClass:"columns is-desktop"},[_c('div',{staticClass:"column is-flex is-align-items-center"},[_c('div',{staticClass:"registerForm m-auto is-flex is-flex-direction-column is-justify-content-space-evenly px-3 py-4"},[_c('h2',{staticClass:"pb-2"},[_vm._v(_vm._s(_vm.$t("forms.candidate.register_free")))]),_c('ClientRegistrationForm')],1)]),_c('div',{staticClass:"column has-background-white p-5 is-flex is-flex-wrap-wrap is-justify-content-space-evenly min-fullheight"},[_c('div',{staticClass:"whiteBackground has-background-white is-flex is-flex-wrap-wrap is-align-items-center"},[_c('div',[_c('router-link',{attrs:{"to":"/login"}},[_c('img',{staticClass:"mb-2",attrs:{"src":"/img/logo-skribix.png","alt":_vm.$t('common.img_alts.skribix_logo')}})]),_c('h2',{staticClass:"mb-5",domProps:{"innerHTML":_vm._s(_vm.$t('skribix.hero.we_write_texts'))}}),_c('ul',{staticClass:"testimonyCard is-flex is-flex-direction-column is-justify-content-space-evenly m-2"},[_c('li',{staticClass:"is-flex py-2"},[_c('UserAvatarBigSize',{attrs:{"user":{
                avatar: _vm.chosenTestimony.image,
              },"size":"medium","imgAlt":_vm.$t('auth.register.as.client.testimonials.testimony', {
                  user: _vm.chosenTestimony.user,
                  job: _vm.chosenTestimony.job,
                  company: _vm.chosenTestimony.company,
                })}}),_c('div',[_c('p',{staticClass:"body1 has-text-blue bold"},[_vm._v(" "+_vm._s(_vm.chosenTestimony.user)+" ")]),_c('p',{staticClass:"body2"},[_vm._v(" "+_vm._s(_vm.chosenTestimony.job)+" "),_c('span',{staticClass:"has-text-pink"},[_vm._v(" "+_vm._s(_vm.chosenTestimony.company)+" ")])])])],1),_c('li',{staticClass:"card has-border-none has-background-pink-light px-4 py-2"},[_c('p',{staticClass:"body1 has-text-blue jagged-bottom"},[_vm._v(" "+_vm._s(_vm.chosenTestimony.testimony)+" ")])])])],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }