<template>
  <form class="has-background-white">
    <div
      v-for="(element, index) in fieldsCompanyProfile"
      class="my-2"
      :key="index"
      style="height: 100%"
    >
      <div v-if="element.disabled !== true">
        <span class="is-flex">
          <p class="has-text-blue bold" v-if="element.title">
            {{ element.title }}
          </p>
          <span class="iconSize ml-2" v-if="element.required">
            <i class="fas fa-asterisk"></i>
          </span>
        </span>

        <component
          v-if="componentReady"
          :key="index"
          :indexStep="index"
          :indexField="index"
          :is="element.name"
          :field="element"
          :isSmallField="element.small"
          :isNextStep="isAddCompanyActive"
          @save-result="saveResult"
          class="is-flex is-flex-direction-column is-justify-content-space-evenly mt-1"
        />
      </div>
    </div>
    <div class="is-flex is-justify-content-flex-end">
      <b-button
        inverted
        type="is-pink"
        class="button is-large has-border-pink has-hover-bottom"
        @click.prevent="addCompanyHandler"
        :disabled="disabled"
      >
        {{ $t("common.actions.register") }}
      </b-button>
    </div>
  </form>
</template>
<script>
import { mapActions } from "vuex";
import AddressAutocomplete from "@/components/Model/Form/FormItem/AddressAutocomplete";
import BriefText from "@/components/Model/Form/FormItem/BriefText";
import BriefSwitch from "@/components/Model/Form/FormItem/BriefSwitch";
import CardValidationField from "@/components/UI/card/CardValidationField";

export default {
  name: "CreateCompanyForm",
  components: {
    AddressAutocomplete,
    BriefText,
    BriefSwitch,
    CardValidationField,
  },
  props: {
    userReference: {
      type: String,
    },
  },
  data() {
    return {
      isAddCompanyActive: false,
      componentReady: true,
      disabled: false,
    };
  },

  computed: {
    company: {
      get() {
        return this.$store.state.company.newCompany;
      },
      set(value) {
        this.$store.commit("company/setNewCompany", value);
      },
    },
    fieldsCompanyProfile() {
      return [
        {
          title: this.$t("common.fields.name"),
          name: "BriefText",
          value_name: "name",
          required: true,
          value: " ",
        },
        {
          title: this.$t("common.fields.address"),
          name: "AddressAutocomplete",
          value_name: "address",
          required: true,
          value: " ",
          changeCountryValue: 4,
          changeCityValue: 3,
          changeZipcodeValue: 2,
        },
        {
          isEditableFormat: false,
          title: this.$t("common.fields.zip_code"),
          name: "BriefText",
          value_name: "zipcode",
          required: true,
          value: " ",
          regex: "\\d{5}$",
          hasErrorMessage: this.$t("error.validation.zip_code_valid"),
        },
        {
          title: this.$t("common.fields.city"),
          name: "BriefText",
          value_name: "city",
          required: true,
          value: " ",
        },
        {
          title: this.$t("common.fields.country"),
          name: "BriefText",
          value_name: "country",
          required: true,
          value: " ",
          disabled: true,
        },
        {
          title: this.$t("forms.company.siren"),
          name: "BriefText",
          value_name: "siren",
          required: true,
          value: " ",
        },
        {
          title: this.$t("forms.company.tva"),
          name: "BriefText",
          value_name: "tva",
          required: false,
          regex:
            "[a-zA-Z]+([+-]?(?=\\.\\d|\\d)(?:\\d+)?(?:\\.?\\d*))(?:[eE]([+-]?\\d+))?",
          hasErrorMessage: this.$t("error.validation.tva_valid"),
          value: " ",
        },
        {
          title: this.$t("invoice.variants.payment_method.monthly"),
          name: "BriefSwitch",
          value_name: "monthly_payment",
          required: false,
          value: false,
        },
      ];
    },
  },
  methods: {
    ...mapActions("company", ["addCompany"]),
    saveResult(result) {
      this.company[result.value_name] = result.value;
      this.fieldsCompanyProfile[result.index].value = result.value;

      if (result.index_field === 1) {
        this.forceRerender();
      }
    },
    async forceRerender() {
      this.componentReady = false;
      await this.$nextTick();
      this.componentReady = true;
    },
    addCompanyHandler() {
      this.disabled = true;
      this.isAddCompanyActive = true;
      this.validStep(this.fieldsCompanyProfile);

      new Promise((resolve, reject) => {
        this.fieldsCompanyProfile.isStepValid === true ? resolve() : reject();
      })
        .then(() => {
          this.company["user_reference"] = this.userReference || null;

          this.addCompany()
            .then((response) => {
              this.isAddCompanyActive = false;
              this.$emit("close-modal-add-company", response.data.company);
              this.disabled = false;
              this.$store.dispatch("notify", {
                type: "is-success",
                message: this.$t("company.actions.create.messages.success"),
              });
            })
            .catch((e) => {
              this.$store.dispatch("fireError", e);
              this.disabled = false;
            });
        })
        .catch((e) => {
          console.log(e);
        });
    },
    validStep(steps) {
      steps.forEach((element) => {
        if (element.required === true) {
          if (
            (Array.isArray(element.value) &&
              element.value.every((elem) => elem === null)) ||
            (Array.isArray(element.value) && element.value.length === 0) ||
            (Array.isArray(element.value) && element.hasError)
          ) {
            element.isValid = false;
          } else if (
            element.value &&
            typeof element.value === "object" &&
            Object.keys(element.value) &&
            Object.keys(element.value).length === 0
          ) {
            element.isValid = false;
          } else if (
            !element.value ||
            element.value === " " ||
            element.hasError
          ) {
            element.isValid = false;
          } else {
            element.isValid = true;
          }
        } else {
          element.isValid = Boolean(!element.hasError);
        }
      });

      if (steps.filter((element) => element.isValid === false).length === 0) {
        steps.isStepValid = true;
      } else {
        steps.isStepValid = false;
      }
    },
  },
};
</script>

<style scoped>
.iconSize {
  font-size: 0.5em;
}
</style>
