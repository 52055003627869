import { addPreffixToAllStrings } from "@/utils/index";
import Vue from "vue";
import VueI18n from "vue-i18n";
import loadLocaleMessages from "./loadLocaleMessages";
import { tp, ts } from "./i18nHelper";

Vue.use(VueI18n);
Vue.prototype.$tp = tp;
Vue.prototype.$ts = ts;

const user = JSON.parse(localStorage.getItem("user"));
const locale = user ? user.locale : process.env.VUE_APP_I18N_LOCALE || "fr";

let messages = loadLocaleMessages(
  require.context("./locales", true, /[A-Za-z0-9-_,\s]+\.json$/i)
);

if (Boolean(process.env.VUE_APP_I18N_DEBUG) === true) {
  messages = addPreffixToAllStrings(messages, "🌍");
}

export default new VueI18n({
  locale: locale,
  fallbackLocale: process.env.VUE_APP_I18N_LOCALE || "fr",
  messages: messages,
  missing: (locale, key) => {
    process.env.NODE_ENV === "development" &&
      console.error(
        `Translation for key "${key}" is missing in locale "${locale}".`
      );
  },
});
