<template>
  <b-select
    :placeholder="$t('invoice.variants.payment_method.choose_payment_method')"
    v-model="billingTypeModel"
    @change.native="handleChange()"
  >
    <option :value="1">{{ $t("invoice.variants.billed.per_word") }}</option>
    <option :value="2">{{ $t("invoice.variants.billed.per_text") }}</option>
    <option :value="3">{{ $t("invoice.variants.billed.per_order") }}</option>
    <option :value="4">{{ $t("invoice.variants.billed.per_unit") }}</option>
  </b-select>
</template>

<script>
export default {
  name: "BillingType",
  props: ["billingType"],
  model: {
    props: "billingType",
    event: "change",
  },
  methods: {
    handleChange() {
      this.$emit("change", this.billingTypeModel);
    },
  },
  data() {
    return {
      billingTypeModel: 1,
    };
  },
  created() {
    this.billingTypeModel = this.billingType ?? 1;
  },
};
</script>
