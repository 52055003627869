<template>
  <article class="hero box p-4 is-pink-light my-2">
    <figure>
      <img
        style="height: 7rem; width: 14rem"
        src="/img/box/workshop.png"
        :title="$t('skribix.boxes.workshops.img_alt')"
        :alt="$t('skribix.boxes.workshops.img_alt')"
      />
    </figure>
    <h4 class="has-text-pink">
      {{ $t("skribix.boxes.workshops.title") }}
    </h4>
    <p class="body1 has-text-pink">
      {{ $t("skribix.boxes.workshops.workshop_candidate_skribix.description") }}
    </p>
    <a
      class="mt-4 has-text-pink is-flex is-align-items-center"
      href="https://academy.skribix.com/bundles/candidats-fr"
      target="_blank"
    >
      {{ $t("common.actions.sign_up") }} <IconArrowRight class="ml-2" />
    </a>
  </article>
</template>
<script>
export default {
  name: "BoxWorkshopSkribixCandidate",
};
</script>
