<template>
  <Modal v-if="isModalCreateActive" class="left-modal">
    <template v-slot:header>
      <h3>{{ $t("forms.orders.add_company") }}</h3>

      <CloseModalButton @click="closeModalAddCompany" />
    </template>
    <div>
      <CreateCompanyForm
        :user-reference="userReference"
        @close-modal-add-company="saveCompany"
      />
    </div>
  </Modal>
</template>
<script>
import CloseModalButton from "@/components/UI/buttons/CloseModalButton";
import CreateCompanyForm from "@/components/Model/Company/Form/CreateCompanyForm";
import Modal from "@/components/UI/Modal";

export default {
  name: "CreateCompanyModal",
  components: { CloseModalButton, CreateCompanyForm, Modal },
  props: {
    isModalCreateActive: {},
    userReference: {
      type: String,
    },
  },
  methods: {
    saveCompany(response) {
      this.$emit("save-company", response);
    },
    closeModalAddCompany() {
      this.$emit("close-modal-add-company");
    },
  },
};
</script>
