<template>
  <div>
    <main class="section" v-if="isLoading">
      <div v-if="candidateProfile.preferences.contract_signed && !writer">
        <p>
          {{ $t("forms.writer.completed") }}
        </p>
        <p>
          {{ $t("forms.writer.choose_text") }}
        </p>
        <p>
          {{ $t("forms.writer.question") }}
        </p>
        <p>{{ $t("forms.writer.good_luck") }}</p>
      </div>
      <div v-if="!candidateProfile.preferences.contract_signed && !writer">
        <p>{{ $t("forms.stripe.create_to_continue") }}</p>
      </div>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "CreateWriterAccount",
  props: ["field", "indexStep", "indexField"],
  components: {},
  data() {
    return {
      isLoading: true,
      isNextStep: false,
    };
  },

  computed: {
    ...mapGetters("auth", ["writer", "candidate"]),
    candidateProfile: {
      get() {
        return this.$store.state.user.candidateProfile;
      },
      set(value) {
        this.$store.commit("user/setCandidateProfile", value);
      },
    },
  },
};
</script>
<style scoped>
.section {
  padding: 0;
}
p {
  margin-bottom: 40px;
}
</style>
