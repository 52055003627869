<template>
  <b-modal v-if="orderWithFailedPayment" active full-screen :can-cancel="false">
    <article
      class="is-flex is-flex-direction-column is-align-items-center is-justify-content-center my-2 has-border-none py-6"
    >
      <div class="card-content has-text-centered pb-4">
        <h4>
          <slot name="title">{{
            $t("error.validation.subscription_payment_error")
          }}</slot>
        </h4>
        <p class="body1">
          {{
            $t("error.validation.payment_failed", {
              reference: orderWithFailedPayment.reference,
            })
          }}
          <br />
          {{ $t("invoice.actions.pay_invoice.description") }}
        </p>
      </div>

      <b-button
        inverted
        tag="a"
        class="has-border-blue has-hover-bottom mx-1 is-flex is-align-items-center is-justify-content-center"
        size="is-large"
        type="is-link"
        :href="orderWithFailedPayment.subscription_hosted_invoice_url"
      >
        {{ $t("invoice.actions.pay_invoice") }}
      </b-button>
    </article>
  </b-modal>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PopupOrderSubscriptionWithFailedPayment",
  computed: {
    ...mapState("order", ["orderWithFailedPayment"]),
  },
};
</script>
